import React, { useState, useEffect, useCallback } from "react";
import Switch from "react-switch";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";
import { debounce } from "lodash";
import Pagination from "../../component/Pagination/Pagination";
import AddEmailTemplate from "../../Modal/AddEmailTemplate/AddEmailTemplate";
import EmailTemplateService from "../../services/EmailTemplateService";
// import Storage from "../../services/Storage";
import CustomSpinner from "../../component/CustomSpinner/CustomSpinner";
import { RECORDS_PER_PAGE } from "../../constants/constant.app";
// import ReactPaginate from 'react-paginate';

export default function EmailTemplates(props) {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [openAddEmailTemplatePage, setOpenAddEmailTemplatePage] = useState(false);
  const [emailTemplateToUpdate, setEmailTemplateToUpdate] = useState(null);
  const [search, setSearch] = useState("");
  const [active, setActive] = useState("");
  // const [active, setActive] = useState("");
  // const [userDetail, setUserDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const pageRange = 3;
  const marginPagesDisplayed = 2;
  // const [showAlert, setShowAlert] = useState(false);
  // const [deleteIndex, setDeleteIndex] = useState(null);

  useEffect(() => {
    getEmailTemplateList(1);
  }, []);

  // useEffect(() => {
  //   // debounce(getEvents, 0.5);
  //   getEmailTemplateList(1);
  // }, [search]);

  useEffect(() => {
    debouncedChangeHandler(search);
  }, [search]);

  const searchEmailTemplateList = (search) => {
    setPage(1);
    getEmailTemplateList(1, active, search)
  }

  const debouncedChangeHandler = useCallback(
    debounce(searchEmailTemplateList, 1000),
    []
  )


  const openDeleteEmailTemplatePopup = async (index) => {
    // setShowAlert(true);
    // setDeleteIndex(index);
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete email template?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      background: "#191c24",
    });
    if (result.isConfirmed) {
      deleteEmailTemplate(index);
    } else if (result.dismiss === Swal.DismissReason.cancel) {
    }
  };

  const deleteEmailTemplate = async (index) => {
    // setShowAlert(false);
    const emailTemplate = emailTemplates[index];
    const deleteEmailTemplateObj = {
      emailTemplateId: emailTemplate.emailTemplateId,
      isDeleted: true,
    };
    setIsLoading(true);
    try {
      await EmailTemplateService.updateEmailTemplate(deleteEmailTemplateObj);
      const message = 'Email template deleted successfully';
      setIsLoading(false);
      getEmailTemplateList(1, active, search);
      setPage(1);
      await Swal.fire({
        title: "Delete email template",
        text: message,
        icon: "success",
        confirmButtonText: "Ok!",
        background: "#191c24",
      });

    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: "Delete email template",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    }
  };

  const onPageChange = (page) => {
    getEmailTemplateList(page, active, search);
    setPage(page);
  };

  const getEmailTemplateList = async (page, isActive = null, search) => {
    try {
      const getEmailTemplateObj = {
        page,
        limit: RECORDS_PER_PAGE,
      }
      if (search) {
        getEmailTemplateObj.search = search;
      }
      if (isActive === "true") {
        getEmailTemplateObj.isActive = true;
      } else if (isActive === "false") {
        getEmailTemplateObj.isActive = false;
      }

      setIsLoading(true);
      const emailTemplateFromService = await EmailTemplateService.listEmailTemplates(getEmailTemplateObj);
      const {
        data: { data, totalPages },
      } = emailTemplateFromService;
      setPageCount(totalPages);
      setEmailTemplates([...data]);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: "List email templates",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    }
  };

  const inactiveEmailTemplate = async (index) => {
    const emailTemplate = emailTemplates[index];
    emailTemplate.isActive = !emailTemplate.isActive;
    await updateEmailTemplate(emailTemplate);
    setEmailTemplates([...emailTemplates]);
  };

  const updateEmailTemplate = async (emailTemplate) => {
    const body = {
      emailTemplateId: emailTemplate.emailTemplateId,
      name: emailTemplate.name,
      isActive: emailTemplate.isActive,
    };
    try {
      setIsLoading(true);
      await EmailTemplateService.updateEmailTemplate(body);
      const message = "Email template updated successfully";
      setIsLoading(false);
      await Swal.fire({
        title: "Update email template",
        text: message,
        icon: "success",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: "Update email template",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    }
  };

  const openUpdateEmailTemplateModal = (emailTemplate) => {
    console.log("props", props);
    props.history.push({
      pathname: '/add-email-template',
      state: emailTemplate
    });
    // setEmailTemplateToUpdate({ ...emailTemplate });
    // setOpenAddEmailTemplatePage(!openAddEmailTemplatePage);
  };

  const closeAddEmailTemplateModal = () => {
    setOpenAddEmailTemplatePage(!openAddEmailTemplatePage);
    setEmailTemplateToUpdate(null);
    getEmailTemplateList(1, active, search);
    setPage(1);
  };

  const resetFilter = async () => {
    setActive("");
    getEmailTemplateList(1, "", search);
  }

  // const resetFilter = async () => {
  //   setActive("");
  //   getCategoryList(1, "");
  // }
  return (
    <div>
      <div class="page-header">
        <h3 class="page-title"> Email Templates </h3>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">
              <button
                class="btn btn-primary"
                // onClick={() => setOpenAddEmailTemplatePage(!openAddEmailTemplatePage)}
                onClick={() => openUpdateEmailTemplateModal(null)}
              >
                Add Template
              </button>
            </li>
          </ol>
        </nav>
      </div>

      <div className="row">
        <AddEmailTemplate
          show={openAddEmailTemplatePage}
          emailTemplate={emailTemplateToUpdate}
          closeModal={closeAddEmailTemplateModal}
        ></AddEmailTemplate>
        <div
          className="col-lg-12 grid-margin stretch-card"
          style={{ width: "100%" }}
        >
          <div className="card">
            <div className="card-body">
              {isLoading && <CustomSpinner></CustomSpinner>}
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <Form.Group>
                    <label htmlFor="exampleInputPassword1">Search</label>
                    <Form.Control
                      type="text"
                      id="search"
                      placeholder="Search.."
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <Form.Group>
                    <label htmlFor="type">Active</label>
                    <div>
                      <select
                        className="form-control"
                        onChange={(e) => setActive(e.target.value)}
                        value={active}
                      >
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12" style={{ margin: "auto 0px" }}>
                  <button class="btn btn-primary" onClick={() => getEmailTemplateList(1, active, search)}>
                    Apply
                  </button>&nbsp;&nbsp;
                  <button type="button" className="btn btn-warning btn-icon-text" onClick={resetFilter}>
                    Reset
                  </button>
                </div>
              </div>
              {/* <p className="card-description"> Add className <code>.table</code> */}
              {/* </p> */}
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Subject</th>
                      <th>Active</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {emailTemplates.map((c, index) => (
                      <tr>
                        <td class="">{c.name}</td>
                        <td class="">{c.subject}</td>
                        <td>
                          <Switch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor="#0090e7"
                            height={20}
                            width={40}
                            onChange={() => inactiveEmailTemplate(index)}
                            checked={c.isActive}
                          />
                        </td>
                        <td>
                          <i
                            className="mdi mdi-table-edit"
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "#0090e7",
                            }}
                            title="Edit email template"
                            onClick={() => openUpdateEmailTemplateModal(c)}
                          ></i>
                          &nbsp;&nbsp;
                         <i
                            className="mdi mdi-delete"
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "#0090e7",
                            }}
                            title="Delete email template"
                            onClick={() => openDeleteEmailTemplatePopup(index)}
                          ></i>
                        </td>
                      </tr>
                    ))}
                    {emailTemplates && emailTemplates.length === 0 && (
                      <tr>
                        <td colSpan="4" style={{ textAlign: "center" }}>
                          No email templates available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {pageCount > 1 && (
                  <Pagination
                    totalRecords={pageCount * RECORDS_PER_PAGE}
                    itemsCountPerPage={RECORDS_PER_PAGE}
                    page={page}
                    pageCount={pageCount}
                    pageRangeDisplayed={pageRange}
                    marginPagesDisplayed={marginPagesDisplayed}
                    onPageChange={onPageChange}
                  ></Pagination>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

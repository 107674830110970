import React, { useState, useEffect, useCallback } from "react";
import Switch from "react-switch";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Pagination from "../../component/Pagination/Pagination";
import AddEvent from "../../Modal/AddEvent/AddEvent";
import EventService from "../../services/EventService";
import UtilsService from "../../services/UtilsService";
import CustomSpinner from "../../component/CustomSpinner/CustomSpinner";
import { RECORDS_PER_PAGE } from "../../constants/constant.app";
import { debounce } from "lodash";
import Storage from "../../services/Storage";
// import ReactPaginate from 'react-paginate';
export default function Event(props) {
  const userDetail = Storage.getUserDetail();
  const [page, setPage] = useState(1);
  const [events, setEvents] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState("");
  const [active, setActive] = useState("");
  const [recommended, setRecommended] = useState("");
  const [trending, setTrending] = useState("");
  const [publish, setPublish] = useState("");
  const [eventStartDate, setEventStartDate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(null);
  const [openAddEventPage, setOpenAddEventPage] = useState(false);
  const [eventToEdit, setEventToEdit] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchInitialState, setIsSearchInitialState] = useState(true);
  const [isActiveInitialState, setIsActiveInitialState] = useState(true);
  const [isStatusInitialState, setIsStatusInitialState] = useState(true);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [status, setStatus] = useState("");
  const pageRange = 3;
  const marginPagesDisplayed = 2;
  let isActiveState = props?.location?.state?.isActive;
  let statusState = props?.location?.state?.status;
  if ((isActiveState === true || isActiveState === false)) {
    isActiveState = isActiveState.toString()
  } else {
    isActiveState = null;
  }
  // const [showAlert, setShowAlert] = useState(false);
  // const [deleteIndex, setDeleteIndex] = useState(null);

  useEffect(() => {
    // debounce(searchUserList, 1000);
    if (isActiveState) {
      setActive(isActiveState);
    }
    if (['completed', 'in-progress', 'pending'].includes(statusState)) {
      setStatus(statusState);
    }
    // if (isUnverifiedState === true || isUnverifiedState === false) {
    //   setStatus(isUnverifiedState ? 'unverified' : null);
    // }
    if (!(isActiveState === "true" || isActiveState === "false" || ['completed', 'in-progress', 'pending'].includes(statusState))) {
      getEvents(1)
    }
  }, []);

  useEffect(() => {
    if (isFirstTime && !isActiveInitialState) {
      getEvents(1, null, search)
      setIsFirstTime(false)
    }
    setIsActiveInitialState(false)
  }, [active]);

  useEffect(() => {
    if (isFirstTime && !isStatusInitialState) {
      getEvents(1, null, search)
      setIsFirstTime(false)
    }
    setIsStatusInitialState(false)
  }, [status]);

  useEffect(() => {
    if (!isSearchInitialState) {
      debouncedChangeHandler(search);
    }
    setIsSearchInitialState(false)
  }, [search]);

  const searchEventList = (search) => {
    setPage(1);
    const filterObj = {
      active,
      recommended,
      publish,
      trending,
      eventStartDate,
      eventEndDate,
      status
    };
    getEvents(1, filterObj, search)
  }

  const debouncedChangeHandler = useCallback(
    debounce(searchEventList, 1000),
    []
  )


  const handleRecommendationChange = async (index) => {
    const event = events[index];
    event.isRecommended = !event.isRecommended;
    try {
      await editEvent(event);
      setEvents([...events]);
    } catch (e) {
      console.log("e", e);
    }
  };
  const handleIsTrendingChange = async (index) => {
    const event = events[index];
    event.isTrending = !event.isTrending;
    try {
      await editEvent(event);
      setEvents([...events]);
    } catch (e) {
      console.log("e", e);
    }
  };

  const handleIsActiveChange = async (index) => {
    const event = events[index];
    event.isActive = !event.isActive;
    try {
      await editEvent(event);
      setEvents([...events]);
    } catch (e) {
      console.log("e", e);
    }
  };

  const handleIsPublishChange = async (index) => {
    const event = events[index];
    event.isPublish = !event.isPublish;
    try {
      await editEvent(event);
      setEvents([...events]);
    } catch (e) {
      console.log("e", e);
    }
  };

  const openDeleteEventPopup = async (index) => {
    // setShowAlert(true);
    // setDeleteIndex(index);
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete event?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      background: "#191c24",
    });
    if (result.isConfirmed) {
      deleteEvent(index);
    } else if (result.dismiss === Swal.DismissReason.cancel) {
    }
  };

  const getEvents = async (page, filterObj, search) => {
    console.log("-------------------------------------------")
    const eventObj = {
      page,
      pageSize: RECORDS_PER_PAGE,
      search,
      eventStartDate:
        filterObj && filterObj.hasOwnProperty("eventStartDate")
          ? filterObj.eventStartDate
          : eventStartDate,
      eventEndDate:
        filterObj && filterObj.hasOwnProperty("eventEndDate")
          ? filterObj.eventEndDate
          : eventEndDate,
    };
    const activeToConsider =
      filterObj && filterObj.active ? filterObj.active : active;
    if (activeToConsider === "true") {
      eventObj.active = true;
    } else if (activeToConsider === "false") {
      eventObj.active = false;
    }

    const trendingToConsider =
      filterObj && filterObj.trending ? filterObj.trending : trending;
    if (trendingToConsider === "true") {
      eventObj.isTrending = true;
    } else if (trendingToConsider === "false") {
      eventObj.isTrending = false;
    }

    const recommendedToConsider =
      filterObj && filterObj.recommended ? filterObj.recommended : recommended;
    if (recommendedToConsider === "true") {
      eventObj.recommended = true;
    } else if (recommendedToConsider === "false") {
      eventObj.recommended = false;
    }

    const publishToConsider =
      filterObj && filterObj.publish ? filterObj.publish : publish;
    if (publishToConsider === "true") {
      eventObj.publish = true;
    } else if (publishToConsider === "false") {
      eventObj.publish = false;
    }

    const statusToConsider =
      filterObj && filterObj.status ? filterObj.status : status;
    if (statusToConsider) {
      eventObj.status = statusToConsider;
    }

    setIsLoading(true);
    try {
      const events = await EventService.listEvent(eventObj);
      const { data, totalPages } = events;
      setEvents(data);
      setPageCount(totalPages);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: "List event",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    }
  };

  const editEvent = async (event) => {
    try {
      const obj = {
        isRecommended: event.isRecommended,
        isTrending: event.isTrending,
        isActive: event.isActive,
        isPublish: event.isPublish,
        id: event.eventId,
      };
      setIsLoading(true);
      await EventService.editEvent(obj);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: "Edit event",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    }
  };

  const deleteEvent = async (index) => {
    const event = events[index];
    const deleteEventObj = {
      eventId: event.eventId,
    };
    try {
      setIsLoading(true);
      await EventService.deleteEvent(deleteEventObj);
      await getEvents(1, null, search);
      setPage(1);
      setIsLoading(false);
      const message = "Event deleted successfully";
      await Swal.fire({
        title: "Delete event",
        text: message,
        icon: "success",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: "Delete event",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    }
    // call api to delete event
    //
    // setDeleteIndex(null);
  };

  const onPageChange = (page) => {
    getEvents(page, null, search);
    setPage(page);
  };

  const openEditEventModal = (event) => {
    console.log("props", props);
    props.history.push({
      pathname: '/add-event',
      state: event
    });
    // setEventToEdit({ ...event });
    // setOpenAddEventPage(!openAddEventPage);
  };

  const openPaymentHistoryPage = (event) => {
    props.history.push({
      pathname: '/payments',
      state: { eventId: event.eventId }
    });
  }

  const closeAddEventModal = () => {
    setOpenAddEventPage(!openAddEventPage);
    setEventToEdit(null);
    getEvents(1, null, search);
    setPage(1);
  };

  const resetFilter = async () => {
    setActive("");
    setRecommended("");
    setPublish("");
    setTrending("");
    setEventStartDate(null);
    setEventEndDate(null);
    const filterObj = {
      active: "all",
      recommended: "all",
      publish: "all",
      trending: "all",
      eventStartDate: null,
      eventEndDate: null,
    };
    getEvents(1, filterObj, search);
  };
  return (
    <div>
      <div class="page-header">
        <h3 class="page-title"> Events </h3>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">
              <button
                class="btn btn-primary"
                onClick={() => openEditEventModal(null)}
              // onClick={() => setOpenAddEventPage(!openAddEventPage)}
              >
                Add Event
              </button>
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        {openAddEventPage && <AddEvent
          event={eventToEdit}
          show={openAddEventPage}
          closeModal={closeAddEventModal}
        ></AddEvent>}
        <div class="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              {isLoading && <CustomSpinner></CustomSpinner>}
              {/* <p className="card-description"> Add className <code>.table</code> */}
              {/* </p> */}
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <Form.Group>
                    <label htmlFor="exampleInputPassword1">Search</label>
                    <Form.Control
                      type="text"
                      id="search"
                      placeholder="Search.."
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <Form.Group>
                    <label htmlFor="exampleInputPassword1">
                      Event Start Date
                    </label>
                    <DatePicker
                      selected={eventStartDate}
                      onChange={(date) => setEventStartDate(date)}
                      showTimeSelect={false}
                      // filterTime={filterPassedTime}
                      dateFormat="MMMM d, yyyy"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <Form.Group>
                    <label htmlFor="exampleInputPassword1">
                      Event End Date
                    </label>
                    <DatePicker
                      selected={eventEndDate}
                      onChange={(date) => setEventEndDate(date)}
                      showTimeSelect={false}
                      // filterTime={filterPassedTime}
                      dateFormat="MMMM d, yyyy"
                    />
                  </Form.Group>
                </div>

                {/* </div>
              <div className="row"> */}
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <Form.Group>
                    <label htmlFor="type">Active</label>
                    <div>
                      <select
                        className="form-control"
                        onChange={(e) => setActive(e.target.value)}
                        value={active}
                      >
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <Form.Group>
                    <label htmlFor="type">Recommended</label>
                    <div>
                      <select
                        className="form-control"
                        onChange={(e) => setRecommended(e.target.value)}
                        value={recommended}
                      >
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <Form.Group>
                    <label htmlFor="type">Trending</label>
                    <div>
                      <select
                        className="form-control"
                        onChange={(e) => setTrending(e.target.value)}
                        value={trending}
                      >
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <Form.Group>
                    <label htmlFor="type">Publish</label>
                    <div>
                      <select
                        className="form-control"
                        onChange={(e) => setPublish(e.target.value)}
                        value={publish}
                      >
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <Form.Group>
                    <label htmlFor="type">Status</label>
                    <div>
                      <select
                        className="form-control"
                        onChange={(e) => setStatus(e.target.value)}
                        value={status}
                      >
                        <option value="">All</option>
                        <option value="completed">Completed</option>
                        <option value="pending">Pending</option>
                        <option value="in-progress">In Progress</option>
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12" style={{ margin: "auto 0px" }}>
                  <button class="btn btn-primary" onClick={() => getEvents(1, null, search)}>
                    Apply
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-warning btn-icon-text"
                    onClick={resetFilter}
                  >
                    Reset
                  </button>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Event Id</th>
                      <th>Event Name</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Members</th>
                      <th>Recomm.</th>
                      <th>Trending</th>
                      <th>Publish</th>
                      <th>Total Payment</th>
                      <th>Active</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {events.map((e, index) => (
                      <tr>
                        <td>{e.eventRefId}</td>
                        <td>{e.title}</td>
                        <td>{UtilsService.formatDate(UtilsService.UTCDateToLocalDate(e.eventStartDate, userDetail.zone))}</td>
                        <td>{UtilsService.formatDate(UtilsService.UTCDateToLocalDate(e.eventEndDate, userDetail.zone))}</td>
                        <td>{e.memberCount}</td>
                        <td>
                          <Switch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor="#0090e7"
                            height={20}
                            width={40}
                            onChange={() => handleRecommendationChange(index)}
                            checked={e.isRecommended}
                          />
                        </td>
                        <td>
                          <Switch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor="#0090e7"
                            height={20}
                            width={40}
                            onChange={() => handleIsTrendingChange(index)}
                            checked={e.isTrending}
                          />
                        </td>
                        <td>
                          <Switch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor="#0090e7"
                            height={20}
                            width={40}
                            onChange={() => handleIsPublishChange(index)}
                            checked={e.isPublish}
                          />
                        </td>
                        <td>{e.amount}</td>
                        <td>
                          <Switch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor="#0090e7"
                            height={20}
                            width={40}
                            onChange={() => handleIsActiveChange(index)}
                            checked={e.isActive}
                          />
                        </td>
                        <td>
                          {e.eventStartDate &&
                            new Date(e.eventStartDate) > new Date() && (
                              <>
                                <i
                                  className="mdi mdi-table-edit"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    color: "#0090e7",
                                  }}
                                  title="Edit event"
                                  onClick={() =>
                                    openEditEventModal(e)
                                  }
                                ></i>
                                &nbsp;&nbsp;
                                <i
                                  className="mdi mdi-delete"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    color: "#0090e7",
                                  }}
                                  title="Delete event"
                                  onClick={() => openDeleteEventPopup(index)}
                                ></i>
                              </>
                            )}
                          <i
                            className="mdi mdi-table-edit"
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "#0090e7",
                            }}
                            title="Open Payment history"
                            onClick={() =>
                              openPaymentHistoryPage(e)
                            }
                          ></i>
                        </td>
                      </tr>
                    ))}

                    {events && events.length === 0 && (
                      <tr>
                        <td colSpan="11" style={{ textAlign: "center" }}>
                          No events available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {pageCount > 1 && (
                  <Pagination
                    totalRecords={pageCount * RECORDS_PER_PAGE}
                    itemsCountPerPage={RECORDS_PER_PAGE}
                    page={page}
                    pageCount={pageCount}
                    pageRangeDisplayed={pageRange}
                    marginPagesDisplayed={marginPagesDisplayed}
                    onPageChange={onPageChange}
                  ></Pagination>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { request } from './Request';
import { routes } from '../constants/constant.route';
const { LIST_PAYMENT, GET_TOTAL_PAYMENT } = routes.PAYMENT;
const API_URL = process.env.REACT_APP_API_URL;


export default class PaymentService {
    static listPayment(body) {
        return request('POST', `${API_URL}${LIST_PAYMENT}`, null, body, null);
    }
    static getTotalPayment() {
        return request('GET', `${API_URL}${GET_TOTAL_PAYMENT}`, null, null, null);
    }
}
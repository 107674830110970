import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import ReactQuill from 'react-quill';
// import AlloyEditor from 'alloyeditor-react-component';
import SettingService from "../../services/SettingService";
// import PaymentService from "../../services/PaymentService";
import CustomSpinner from "../../component/CustomSpinner/CustomSpinner";
import "react-datepicker/dist/react-datepicker.css";

const controlObj = {
  value: "",
  valid: null,
  touched: false,
  nullValue: null,
};
export default function Settings(props) {
  // const [value, onChange] = useState(new Date());
  // const [hours, setHours] = useState(0);
  // const [minutes, setMinutes] = useState(0);
  let [isFormValid, setIsFormValid] = useState(false);
  let [emailHeader, setEmailHeader] = useState({ ...controlObj });
  let [emailFooter, setEmailFooter] = useState({ ...controlObj });
  // let [avatar, setAvatar] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const modules = {
    toolbar: {
      container: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image'],
        ['clean'],
        [{ 'align': [] }],
      ]
    },
    // clipboard: {
    //   matchVisual: false,
    // },
    // imageResize: {
    //   parchment: ReactQuill.import('parchment'),
    //   modules: ['Resize', 'DisplaySize']
    // }
  }

  useEffect(() => {
    getSettings();
  }, []);

  function setInitialInputValue(value, input) {
    input.value = value;
    return { ...input };
  }
  const setInputValue = (e, input) => {
    const value = e.target.value;
    input.touched = true;
    input.value = value;
    console.log("input", input);
    return { ...input };
  };

  const setEmailHeaderInput = (value) => {

    // console.log("value", value);
    setEmailHeader({ ...emailHeader, value })
  }

  const setEmailFooterInput = (value) => {
    console.log("value", value);
    setEmailFooter({ ...emailFooter, value })
  }


  // const handleUploadImage = async (event) => {
  //   const file = event.target.files;
  //   // console.log("event", event.target.files[0]);
  //   setAvatar([...file]);
  //   // setAvatarUrl(URL.createObjectURL(file));
  //   console.log("file", file);
  // };


  const handleValidation = () => {
    // let isFormValid = false;
    if (!emailHeader || emailHeader.value === '<p><br></p>') {
      emailHeader.nullValue = true;
    } else {
      emailHeader.nullValue = false;
    }
    emailHeader.valid = !emailHeader.nullValue;

    if (!emailFooter || emailFooter.value === '<p><br></p>') {
      emailFooter.nullValue = true;
    } else {
      emailFooter.nullValue = false;
    }
    emailFooter.valid = !emailFooter.nullValue;

    if (
      emailHeader.valid === true &&
      emailFooter.valid === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    setEmailHeader({ ...emailHeader });
    setEmailFooter({ ...emailFooter });
    // setLat({ ...lat });
    // setLong({ ...long });
    setIsFormValid(isFormValid);
    return isFormValid;
  };

  const getSettings = async () => {
    const settings = await SettingService.listSettings();
    console.log("settings", settings)
    const {
      data,
    } = settings;
    for (let i = 0; i < data.length; i++) {
      const currentData = data[i];
      if (currentData.keyword === 'EMAIL_HEADER') {
        emailHeader.value = currentData.value;
        emailHeader.settingId = currentData.settingId;
        emailHeader.valid = true;
        setEmailHeader({ ...emailHeader });
      }
      if (currentData.keyword === 'EMAIL_FOOTER') {
        emailFooter.value = currentData.value;
        emailFooter.settingId = currentData.settingId;
        emailFooter.valid = true;
        setEmailFooter({ ...emailFooter });
      }
    }
    // setSettings([...data]);
    // return data;
  };

  const updateSetting = async (event) => {
    try {
      event.preventDefault();
      console.log("event detail", event);
      const isSettingFormValid = handleValidation();
      if (isSettingFormValid === false) {
        return;
      }

      const reqArr = [];
      const emailHeaderObj = {
        settingId: emailHeader.settingId,
        value: emailHeader.value
      }
      const emailFooterObj = {
        settingId: emailFooter.settingId,
        value: emailFooter.value
      }
      reqArr.push(emailHeaderObj);
      reqArr.push(emailFooterObj);

      setIsLoading(true);
      await SettingService.editSetting(reqArr);
      setIsLoading(false);

      const message = "Setting updated successfully";
      await Swal.fire({
        title: "Edit setting",
        text: message,
        icon: "success",
        confirmButtonText: "Ok!",
        background: "#191c24",
      });
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: "Edit setting",
        text: message,
        icon: "error",
        confirmButtonText: "Ok!",
        background: "#191c24",
      });
    }
  };

  return (
    <div>
      {/* {console.log("value", value)} */}
      <div className="position-relative">

        <div class="page-header">
          <h3 class="page-title">Settings</h3>
        </div>
        <div className="card">
          <div className="card-body">
            {isLoading && <CustomSpinner></CustomSpinner>}
            {/* <p className="card-description"> Basic form layout </p> */}
            <form className="forms-sample">
              {/* {avatarUrl && <div><img src={avatarUrl} alt="avatar" className="event-img"></img></div>} */}

              {/* <div className="row">
                <Form.Group className="col-12">
                  <label>Post images</label>
                  <div class="custom-file">
                    <input
                      lang="es"
                      type="file"
                      id="customFileLang"
                      class="form-control visibility-hidden form-control-file"
                      multiple
                      onChange={handleUploadImage}
                      accept="image/*"
                    />
                    <label class="custom-file-label" for="customFileLang">
                      Upload image
                    </label>
                  </div>
                </Form.Group>
              </div> */}
              {/* {(posters || avatar) && (
                <div class="form-group">
                  <div className="row">
                    <div className="col-md">
                      <div className="row no-gutters">
                        {posters &&
                          posters.map((p, index) => (
                            <div className="col-sm-4 app-img" key={index}>
                              <img
                                src={`${S3_BUCKET_URL}${p.avatarPath}`}
                                alt="event poster"
                                className="img-fluid"
                              ></img>
                              <button
                                type="button"
                                class="close-button"
                                onClick={() => removePosterImage(index)}
                              ></button>
                            </div>
                          ))}
                        {avatar &&
                          avatar.map((a, index) => (
                            <div className="col-sm-4 app-img" key={index}>
                              <img
                                src={URL.createObjectURL(a)}
                                alt="event poster"
                                className="img-fluid"
                              ></img>
                              <button
                                type="button"
                                class="close-button"
                                onClick={() => removeUploadedPosterImage(index)}
                              ></button>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
              <div className="row">
                <Form.Group className="col-12">
                  <label htmlFor="emailHeader">
                    Email Header <span className="required">*</span>
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={emailHeader.value}
                    onChange={(val) => setEmailHeaderInput(val)}
                    style={{ backgroundColor: '#000' }}
                    modules={modules}
                  />
                  {emailHeader.nullValue && (
                    <div className="error">Email Header should not empty</div>
                  )}
                </Form.Group>
              </div>
              <Form.Group>
                <label htmlFor="EmailFooter">
                  Email Footer <span className="required">*</span>
                </label>
                <ReactQuill
                  // theme="snow"
                  value={emailFooter.value}
                  onChange={(val) => setEmailFooterInput(val)}
                  style={{ backgroundColor: '#000' }}
                  modules={modules}
                />

                {emailFooter.nullValue && (
                  <div className="error">Email Footer should not empty</div>
                )}
              </Form.Group>
              <button
                type="submit"
                className="btn btn-primary mr-2"
                onClick={updateSetting}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import AddEditPost from '../../component/AddEditPost/AddEditPost';

export default function AddEvent(props) {
  const closeModal = () => {
    props.closeModal();
  }

  return <div class="modal" tabindex="-1" role="dialog" style={{ display: props.show === true ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.6)' }}>
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <AddEditPost
            post={props.post}
            closeModal={closeModal}
            type='modal'
          ></AddEditPost>
        </div>
      </div>
    </div>
  </div>
}
import { request } from './Request';
import { routes } from '../constants/constant.route';

const { GET_COUNTRIES } = routes.COUNTRY;
const API_URL = process.env.REACT_APP_API_URL;

export default class CountryService {
    static getCountries() {
        return request('GET', `${API_URL}${GET_COUNTRIES}`, null, null, null);
    }
}

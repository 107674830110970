import { USER_DETAIL } from "../constants/actionType";

// const initialState = {
//     sampleItems: [],
//     item: {}
// }

export default function setUserDetailReducer(state = {}, action) {
    switch (action.type) {
        case USER_DETAIL:
            console.log('post reducer')
            return {
                ...state,
                user: action.user
            }

        default:
            return state;
    }
}
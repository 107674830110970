import { request } from './Request';
import { routes } from '../constants/constant.route';

const { GET_USER_LIST, UPDATE_USER, ADD_USER, CHANGE_PASSWORD, GET_ROLES, GET_USER_COUNTS, GET_USER_LOGIN_HISTORY } = routes.USER;
const API_URL = process.env.REACT_APP_API_URL;

export default class UserService {
    static getUserList(body) {
        return request('POST', `${API_URL}${GET_USER_LIST}`, null, body, null);
    }

    static updateUserByAdmin(body) {
        const headers = {
            'content-type': 'multipart/form-data'
        };
        return request('PUT', `${API_URL}${UPDATE_USER}`, null, body, headers);
    }

    static addUserByAdmin(body) {
        const headers = {
            'content-type': 'multipart/form-data'
        };
        return request('POST', `${API_URL}${ADD_USER}`, null, body, headers);
    }

    static changePassword(body) {
        return request('POST', `${API_URL}${CHANGE_PASSWORD}`, null, body, null);
    }

    static getRoles() {
        return request('GET', `${API_URL}${GET_ROLES}`, null, null, null);
    }

    static getUserCount() {
        return request('GET', `${API_URL}${GET_USER_COUNTS}`, null, null, null);
    }

    static getUserLoginHistory(userId, page, limit) {
        const q = `?userId=${userId}&page=${page}&limit=${limit}`
        return request('GET', `${API_URL}${GET_USER_LOGIN_HISTORY}${q}`, null, null, null);
    }
}

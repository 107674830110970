import React, { useState, useEffect, useCallback } from "react";
import Switch from "react-switch";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";
import { debounce } from "lodash";
import Pagination from "../../component/Pagination/Pagination";
import AddUser from "../../Modal/AddUser/AddUser";
import UserService from "../../services/UserService";
import Storage from "../../services/Storage";
import CustomSpinner from "../../component/CustomSpinner/CustomSpinner";
import { RECORDS_PER_PAGE } from "../../constants/constant.app";
// import ReactPaginate from 'react-paginate';

export default function User(props) {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [users, setUsers] = useState([]);
  const [openAddUserPage, setOpenAddUserPage] = useState(false);
  const [UserToUpdate, setUserToUpdate] = useState(null);
  const [search, setSearch] = useState("");
  const [active, setActive] = useState("");
  const [status, setStatus] = useState("");
  const [userDetail, setUserDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isActiveInitialState, setIsActiveInitialState] = useState(true);
  const [isStatusInitialState, setIsStatusInitialState] = useState(true);
  const [isSearchInitialState, setIsSearchInitialState] = useState(true);
  const pageRange = 3;
  const marginPagesDisplayed = 2;
  let isActiveState = props?.location?.state?.isActive;
  if (isActiveState === true || isActiveState === false) {
    isActiveState = isActiveState.toString();
  } else {
    isActiveState = null;
  }
  let isUnverifiedState = props?.location?.state?.isUnverified || null;
  if (isUnverifiedState) {
    isUnverifiedState = "unverified";
  }
  let searchUser = null;
  // const [showAlert, setShowAlert] = useState(false);
  // const [deleteIndex, setDeleteIndex] = useState(null);
  useEffect(() => {
    // debounce(searchUserList, 1000);
    if (isActiveState) {
      setActive(isActiveState);
    }
    if (isUnverifiedState) {
      console.log("setting status value");
      setStatus(isUnverifiedState);
    }

    const userDetail = Storage.getUserDetail();
    setUserDetail(userDetail);
    if (
      !(
        isActiveState === "true" ||
        isActiveState === "false" ||
        isUnverifiedState
      )
    ) {
      getUserList(1, null, search);
    }
  }, []);

  useEffect(() => {
    if (isFirstTime && !isActiveInitialState) {
      getUserList(1, null, search);
      setIsFirstTime(false);
    }
    setIsActiveInitialState(false);
  }, [active]);

  useEffect(() => {
    if (isFirstTime && !isStatusInitialState) {
      getUserList(1, null, search);
      setIsFirstTime(false);
    }
    setIsStatusInitialState(false);
  }, [status]);

  useEffect(() => {
    if (isSearchInitialState === false) {
      debouncedChangeHandler(search);
    } else {
      setIsSearchInitialState(false)
    }
  }, [search]);

  const searchUserList = (search) => {
    if ((search && search.length >= 3) || !search) {
      setPage(1);
      getUserList(1, null, search);
    }
  };

  const debouncedChangeHandler = useCallback(
    debounce(searchUserList, 1000),
    []
  );

  const openDeleteUserPopup = async (index) => {
    // setShowAlert(true);
    // setDeleteIndex(index);
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      background: "#191c24",
    });
    if (result.isConfirmed) {
      deleteUser(index);
    } else if (result.dismiss === Swal.DismissReason.cancel) {
    }
  };

  const deleteUser = async (index) => {
    // setShowAlert(false);
    const user = users[index];
    const deleteUserObj = {
      userId: user.userId,
      isDeleted: true,
    };
    setIsLoading(true);
    try {
      await UserService.updateUserByAdmin(deleteUserObj);
      const message = "User deleted successfully";
      setIsLoading(false);
      getUserList(1, null, search);
      setPage(1);
      await Swal.fire({
        title: "Delete User",
        text: message,
        icon: "success",
        confirmButtonText: "Ok!",
        background: "#191c24",
      });
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: "Delete users",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    }
    // call api to delete event
    //
    // setDeleteIndex(null);
  };

  const onPageChange = (page) => {
    console.log("page", page);
    getUserList(page, null, search);
    setPage(page);
  };

  const getUserList = async (page, isActive = null, search) => {
    console.log("search inside user list", search, page, isActive);
    try {
      const getUserListObj = {
        page,
        limit: RECORDS_PER_PAGE,
      };
      console.log("active", active);
      const activeToConsider = isActive !== null ? isActive : active;
      if (activeToConsider === "true") {
        getUserListObj.active = true;
      } else if (activeToConsider === "false") {
        getUserListObj.active = false;
      }
      if (search) {
        getUserListObj.search = search;
      }
      if (status) {
        getUserListObj.status = status;
      }
      setIsLoading(true);
      const usersFromService = await UserService.getUserList(getUserListObj);
      const {
        data: { data, totalPages },
      } = usersFromService;
      // setTotalRecords(setTotalRecords);
      setPageCount(totalPages);
      setUsers([...data]);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: "List users",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    }
  };

  const inactiveUser = async (index) => {
    const user = users[index];
    user.isActive = !user.isActive;
    await updateUser(user);
    setUsers([...users]);
  };

  const updateUser = async (user) => {
    const body = {
      userId: user.userId,
      isActive: user.isActive,
    };
    try {
      setIsLoading(true);
      await UserService.updateUserByAdmin(body);
      const message = "User updated successfully";
      setIsLoading(false);
      await Swal.fire({
        title: "Update users",
        text: message,
        icon: "success",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: "Update users",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    }
  };

  const openUpdateUserModal = (user) => {
    props.history.push({
      pathname: "/add-user",
      state: {
        user,
        currentUserDetail: userDetail
      },
    });
    // setUserToUpdate({ ...user });
    // setOpenAddUserPage(!openAddUserPage);
  };

  const openPaymentHistoryPage = (user) => {
    props.history.push({
      pathname: "/payments",
      state: { userId: user.userId },
    });
  };

  const openUserLoginHistoryPage = (user) => {
    props.history.push({
      pathname: "/login-history",
      state: { userId: user.userId },
    });
  };

  const closeAddUserModal = () => {
    setOpenAddUserPage(!openAddUserPage);
    setUserToUpdate(null);
    getUserList(1, null, search);
    setPage(1);
  };

  const resetFilter = async () => {
    setActive("");
    getUserList(1, "", search);
  };
  return (
    <div>
      <div class="page-header">
        <h3 class="page-title"> Users </h3>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">
              <button
                class="btn btn-primary"
                // onClick={() => setOpenAddUserPage(!openAddUserPage)}
                onClick={() => openUpdateUserModal(null)}
              >
                Add User
              </button>
            </li>
          </ol>
        </nav>
      </div>

      <div className="row">
        {openAddUserPage && <AddUser
          show={openAddUserPage}
          user={UserToUpdate}
          closeModal={closeAddUserModal}
          currentUserDetail={userDetail}
        ></AddUser>}
        <div
          className="col-12 grid-margin stretch-card"
          style={{ width: "100%" }}
        >
          <div className="card">
            <div className="card-body">
              {isLoading && <CustomSpinner></CustomSpinner>}
              {/* <div className="row">
                <div className="col-10">
                  <h4 className="card-title">Users</h4>
                </div>
                <div>
                  <button class="btn btn-primary" onClick={() => setOpenAddUserPage(!openAddUserPage)}>Add User</button>
                </div>
              </div> */}
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <Form.Group>
                    <label htmlFor="exampleInputPassword1">Search</label>
                    <Form.Control
                      type="text"
                      id="search"
                      placeholder="Search.."
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <Form.Group>
                    <label htmlFor="type">Active</label>
                    <div>
                      <select
                        className="form-control"
                        onChange={(e) => setActive(e.target.value)}
                        value={active}
                      >
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <Form.Group>
                    <label htmlFor="type">Status</label>
                    <div>
                      <select
                        className="form-control"
                        onChange={(e) => setStatus(e.target.value)}
                        value={status}
                      >
                        <option value="">All</option>
                        <option value="verified">Verified</option>
                        <option value="unverified">Not Verified</option>
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12" style={{ margin: "auto 0px" }}>
                  <button
                    class="btn btn-primary"
                    onClick={() => getUserList(1, null, search)}
                  >
                    Apply
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-warning btn-icon-text"
                    onClick={resetFilter}
                  >
                    Reset
                  </button>
                </div>
              </div>
              {/* <p className="card-description"> Add className <code>.table</code> */}
              {/* </p> */}
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>User Ref#</th>
                      <th>Name</th>
                      <th>Email</th>
                      {/* <th>Address</th> */}
                      <th>Phone</th>
                      {/* <th>Timezone</th> */}
                      <th>Role</th>
                      <th>Confirmed?</th>
                      <th>Active</th>
                      {/* <th>Birthdate</th> */}
                      {/* <th>Profession</th>
                    <th>Profile</th> */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((u, index) => (
                      <tr>
                        <td class="">{u.userRefId}</td>
                        <td class="">{u.name}</td>
                        <td>{u.email}</td>
                        {/* <td>{u.address}</td> */}
                        <td>{u.phone}</td>
                        {/* <td>{u.timezone}</td> */}

                        <td>{u.roleId === 1 ? "Admin" : "User"}</td>
                        <td>
                          {u.isUserVerified === false ? (
                            <label class="badge badge-warning">Pending</label>
                          ) : (
                            <label class="badge badge-success">Verified</label>
                          )}
                        </td>
                        <td>
                          {userDetail && userDetail.userId !== u.userId && (
                            <Switch
                              uncheckedIcon={false}
                              checkedIcon={false}
                              onColor="#0090e7"
                              height={20}
                              width={40}
                              onChange={() => inactiveUser(index)}
                              checked={u.isActive}
                            />
                          )}
                        </td>
                        {/* <td>{u.dateOfBirth}</td> */}
                        {/* <td>{u.profession}</td>
                      <td>{u.profile}</td> */}

                        <td>
                          <i
                            className="mdi mdi-table-edit"
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "#0090e7",
                            }}
                            title="Edit user"
                            onClick={() => openUpdateUserModal(u)}
                          ></i>
                          &nbsp;&nbsp;
                          <i
                            className="mdi mdi-bank"
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "#0090e7",
                            }}
                            title="Open Payment history"
                            onClick={() => openPaymentHistoryPage(u)}
                          ></i>
                          &nbsp;&nbsp;
                          <i
                            className="mdi mdi-history"
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "#0090e7",
                            }}
                            title="Open User Login history"
                            onClick={() => openUserLoginHistoryPage(u)}
                          ></i>
                          &nbsp;&nbsp;
                          {userDetail && userDetail.userId !== u.userId && (
                            <i
                              className="mdi mdi-delete"
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                color: "#0090e7",
                              }}
                              title="Delete user"
                              onClick={() => openDeleteUserPopup(index)}
                            ></i>
                          )}
                        </td>
                      </tr>
                    ))}
                    {users && users.length === 0 && (
                      <tr>
                        <td colSpan="8" style={{ textAlign: "center" }}>
                          No users available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {pageCount > 1 && (
                  <Pagination
                    totalRecords={pageCount * RECORDS_PER_PAGE}
                    itemsCountPerPage={RECORDS_PER_PAGE}
                    pageCount={pageCount}
                    page={page}
                    pageRangeDisplayed={pageRange}
                    marginPagesDisplayed={marginPagesDisplayed}
                    onPageChange={onPageChange}
                  ></Pagination>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useCallback } from "react";
import Switch from "react-switch";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";
import { debounce } from "lodash";
import Pagination from "../../component/Pagination/Pagination";
import AddUser from "../../Modal/AddUser/AddUser";
import UserService from "../../services/UserService";
import Storage from "../../services/Storage";
import CustomSpinner from "../../component/CustomSpinner/CustomSpinner";
import { RECORDS_PER_PAGE } from "../../constants/constant.app";
import UtilsService from "../../services/UtilsService";

// import ReactPaginate from 'react-paginate';

export default function UserLoginHistory(props) {
  const userDetail = Storage.getUserDetail();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [userLoginHistory, setUserLoginHistory] = useState([]);
  const [userId, setUserId] = useState(null);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const pageRange = 3;
  const marginPagesDisplayed = 2;
  let searchUser = null;
  // const [showAlert, setShowAlert] = useState(false);
  // const [deleteIndex, setDeleteIndex] = useState(null);
  useEffect(() => {
    // debounce(searchUserList, 1000);
    const userId = props?.location?.state?.userId;
    if (!userId) {
      props.history.push('/users')
    } else {
      setUserId(userId);
      getUserLoginHistory(1, userId);
    }
  }, []);

  const onPageChange = (page) => {
    getUserLoginHistory(page, userId);
    setPage(page);
  };

  const getUserLoginHistory = async (page, userId) => {
    console.log("search inside user list", page);
    try {
      setIsLoading(true);
      const usersHistory = await UserService.getUserLoginHistory(
        userId,
        (page = 1),
        RECORDS_PER_PAGE
      );
      console.log(usersHistory);
      const {
        data: { data, totalPages },
      } = usersHistory;
      console.log("data", data);
      // setTotalRecords(setTotalRecords);
      setPageCount(totalPages);
      console.log(data);
      setUserLoginHistory([...data]);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: "List users",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    }
  };

  return (
    <div className="position-relative">
      {isLoading && <CustomSpinner></CustomSpinner>}
      <div class="page-header">
        <h3 class="page-title">Login history</h3>
      </div>

      <div className="row">
        <div
          className="col-lg-12 grid-margin stretch-card"
          style={{ width: "100%" }}
        >
          <div className="card">
            <div className="card-body" style={{ minWidth: "781px" }}>
              {/* <div className="row">
                <div className="col-10">
                  <h4 className="card-title">Users</h4>
                </div>
                <div>
                  <button class="btn btn-primary" onClick={() => setOpenAddUserPage(!openAddUserPage)}>Add User</button>
                </div>
              </div> */}
              {/* <div className="row">
                <div className="col-3">
                  <Form.Group>
                    <label htmlFor="exampleInputPassword1">Search</label>
                    <Form.Control
                      type="text"
                      id="search"
                      placeholder="Search.."
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className="col-3">
                  <Form.Group>
                    <label htmlFor="type">Active</label>
                    <div>
                      <select
                        className="form-control"
                        onChange={(e) => setActive(e.target.value)}
                        value={active}
                      >
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-3" style={{ margin: "auto 0px" }}>
                  <button class="btn btn-primary" onClick={() => getUserList(1, null, search)}>
                    Apply
                  </button>&nbsp;&nbsp;
                  <button type="button" className="btn btn-warning btn-icon-text" onClick={resetFilter}>
                    Reset
                  </button>
                </div>
              </div>
              */}
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>user ref#</th>
                      <th>Name</th>
                      <th>Device Type</th>
                      <th>Ip Address</th>
                      <th>Os</th>
                      {/* <th>Version</th> */}
                      <th>Login Status</th>
                      <th>Created Date</th>
                      {/* <th>Timezone</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {userLoginHistory.map((u, index) => (
                      <tr>
                        <td>{u.userRefId}</td>
                        <td>{u.username}</td>
                        <td>{u.deviceType}</td>
                        <td>{u.ipAddress}</td>
                        <td>{u.os}</td>
                        {/* <td>{u.version ? u.version : "-"}</td> */}
                        <td class="">{u.loginAttemptStatus ? "Succeed" : "Failed"} {u.loginAttemptStatus}</td>
                        <td>{UtilsService.formatDate(UtilsService.UTCDateToLocalDate(u.createdDate, userDetail.zone))}</td>
                        {/* <td>{u.timezone}</td> */}
                      </tr>
                    ))}
                    {userLoginHistory && userLoginHistory.length === 0 && (
                      <tr>
                        <td colSpan="7" style={{ textAlign: "center" }}>
                          No login history found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {pageCount > 1 && (
                  <Pagination
                    totalRecords={pageCount * RECORDS_PER_PAGE}
                    itemsCountPerPage={RECORDS_PER_PAGE}
                    pageCount={pageCount}
                    page={page}
                    pageRangeDisplayed={pageRange}
                    marginPagesDisplayed={marginPagesDisplayed}
                    onPageChange={onPageChange}
                  ></Pagination>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import AddEditUser from "../../component/AddEditUser/AddEditUser";
import Storage from "../../services/Storage";
import UserService from "../../services/UserService";
import CustomSpinner from "../../component/CustomSpinner/CustomSpinner";
import { notNullValidator } from "../../services/Validation";

const controlObj = {
  value: "",
  valid: null,
  touched: false,
  nullValue: null,
};

function UpdateProfile() {
  let [isFormValid, setIsFormValid] = useState(false);
  let [currentPassword, setCurrentPassword] = useState({ ...controlObj });
  let [visibleCurrentPassword, setVisibleCurrentPassword] = useState(false);
  let [newPassword, setNewPassword] = useState({ ...controlObj });
  let [visibleNewPassword, setVisibleNewPassword] = useState(false);
  let [retypePassword, setRetypePassword] = useState({ ...controlObj });
  let [visibleRetypePassword, setVisibleRetypePassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userDetail = Storage.getUserDetail();

  const setInputValue = (e, input) => {
    const value = e.target.value;
    input.touched = true;
    input.value = value;
    return { ...input };
  };

  const setCurrentPasswordInput = (e) => {
    setCurrentPassword(() => setInputValue(e, currentPassword));
  };

  const setNewPasswordInput = (e) => {
    setNewPassword(() => setInputValue(e, newPassword));
  };

  const setRetypePasswordInput = (e) => {
    setRetypePassword(() => setInputValue(e, retypePassword));
  };

  const handleValidation = () => {
    let isFormValid = false;
    currentPassword = notNullValidator(currentPassword);
    currentPassword.valid = !currentPassword.nullValue;

    newPassword = notNullValidator(newPassword);

    newPassword.valid = !newPassword.nullValue;

    retypePassword = notNullValidator(retypePassword);
    if (newPassword.value !== retypePassword.value) {
      retypePassword.isPasswordMismatch = true;
    } else {
      retypePassword.isPasswordMismatch = false;
    }
    retypePassword.valid = !(
      retypePassword.nullValue || retypePassword.isPasswordMismatch
    );

    if (
      currentPassword.valid === true &&
      newPassword.valid === true &&
      retypePassword.valid === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    setCurrentPassword({ ...currentPassword });
    setNewPassword({ ...newPassword });
    setRetypePassword({ ...retypePassword });
    setIsFormValid(isFormValid);
    return isFormValid;
  };

  const changePasswordApi = async (event) => {
    try {
      event.preventDefault();
      const isChangePasswordFormValid = handleValidation();
      if (isChangePasswordFormValid === false) {
        return;
      }
      const obj = {
        currentPassword: currentPassword.value,
        newPassword: newPassword.value,
      };
      setIsLoading(true);
      const response = await UserService.changePassword(obj);
      setCurrentPassword({ ...controlObj });
      setNewPassword({ ...controlObj });
      setRetypePassword({ ...controlObj });
      setIsLoading(false);
      await Swal.fire({
        title: "Update Password",
        text: "Password updated successfully",
        icon: "success",
        confirmButtonText: "Ok!",
        background: "#191c24",
      });
      // const {
      // 	data: { accessToken, user },
      // } = response;
      return response;
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: "Update Password",
        text: message,
        icon: "error",
        confirmButtonText: "Ok!",
        background: "#191c24",
      });
      // setErrorMessage(message);
    }
  };

  return (
    <div className="row">
      {isLoading && <CustomSpinner></CustomSpinner>}
      <div className="col-md-6 grid-margin stretch-card">
        <AddEditUser
          user={userDetail}
          // isCurrentUser={true}
          currentUserDetail={userDetail}
          type="component"
        ></AddEditUser>
      </div>
      <div
        className="col-md-6 grid-margin stretch-card"
        style={{ display: "block" }}
      >
        <div className="card">
          <div className="card-body">
            {/* <h4 className="card-title">Change Password</h4> */}
            <form className="forms-sample">
              <Form.Group>
                <label htmlFor="currentPassword">Current Password</label>

                <div style={{ position: 'relative' }}>
                  <Form.Control
                    type={visibleCurrentPassword ? "text" : "password"}
                    className="form-control"
                    id="currentPassword"
                    value={currentPassword.value}
                    onChange={setCurrentPasswordInput}
                  />
                  <div
                    style={{ position: 'absolute', marginLeft: '5px', top: '5px', right: '8px' }}
                    onClick={() => setVisibleCurrentPassword(!visibleCurrentPassword)}
                  >
                    {!visibleCurrentPassword && <i className="mdi mdi-eye" style={{
                      cursor: "pointer",
                      color: "#0090e7",
                      fontSize: "18px",
                    }}></i>}
                    {visibleCurrentPassword && <i className="mdi mdi-eye-off" style={{
                      cursor: "pointer",
                      color: "#0090e7",
                      fontSize: "18px",
                    }}></i>}
                  </div>
                </div>

                {currentPassword.nullValue && (
                  <div className="error">
                    Current password should not be empty
                  </div>
                )}
              </Form.Group>
              <Form.Group>
                <label htmlFor="newPassword">New Password</label>
                <div style={{ position: 'relative' }}>
                  <Form.Control
                    type={visibleNewPassword ? "text" : "password"}
                    className="form-control"
                    id="newPassword"
                    value={newPassword.value}
                    onChange={setNewPasswordInput}
                  />
                  <div
                    style={{ position: 'absolute', marginLeft: '5px', top: '5px', right: '8px' }}
                    onClick={() => setVisibleNewPassword(!visibleNewPassword)}
                  >
                    {!visibleNewPassword && <i className="mdi mdi-eye" style={{
                      cursor: "pointer",
                      color: "#0090e7",
                      fontSize: "18px",
                    }}></i>}
                    {visibleNewPassword && <i className="mdi mdi-eye-off" style={{
                      cursor: "pointer",
                      color: "#0090e7",
                      fontSize: "18px",
                    }}></i>}
                  </div>
                  {newPassword.nullValue && (
                    <div className="error">New password should not be null</div>
                  )}
                </div>
              </Form.Group>
              <Form.Group>
                <label htmlFor="retypePassword">Retype Password</label>
                <div style={{ position: 'relative' }}>
                  <Form.Control
                    type={visibleRetypePassword ? "text" : "password"}
                    className="form-control"
                    id="retypePassword"
                    value={retypePassword.value}
                    onChange={setRetypePasswordInput}
                  />
                  <div
                    style={{ position: 'absolute', marginLeft: '5px', top: '5px', right: '8px' }}
                    onClick={() => setVisibleRetypePassword(!visibleRetypePassword)}
                  >
                    {!visibleRetypePassword && <i className="mdi mdi-eye" style={{
                      cursor: "pointer",
                      color: "#0090e7",
                      fontSize: "18px",
                    }}></i>}
                    {visibleRetypePassword && <i className="mdi mdi-eye-off" style={{
                      cursor: "pointer",
                      color: "#0090e7",
                      fontSize: "18px",
                    }}></i>}
                  </div>
                  {retypePassword.nullValue && (
                    <div className="error">Password should not be null</div>
                  )}
                  {retypePassword.isPasswordMismatch && (
                    <div className="error">
                      Password should be same as new password
                    </div>
                  )}
                </div>
              </Form.Group>
              <button
                type="submit"
                className="btn btn-primary mr-2"
                onClick={changePasswordApi}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateProfile;

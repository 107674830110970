import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import "./CustomSpinner.css";

class CustomSpinner extends Component {
    render() {
        console.log("this.props.marginTop", this.props.marginTop);
        return (
            <div className="spinner-middle-con">
                <Spinner className="spinner-middle" animation="border"  />
            </div>
            // <div className="loader">
            //     <div
            //         className="loader-background"
            //         style={{
            //             marginTop:
            //                 this.props && this.props.marginTop ? this.props.marginTop : "0%",
            //         }}
            //     >
            //         <Spinner className="spinner-middle" />

            //     </div>
            // </div>
        );
    }
}

export default CustomSpinner;
import axios from "axios";
import qs from "qs";
// import { history } from 'react-router-dom';
import Storage from "./Storage";
import { detect } from "detect-browser";
import { routes } from "../constants/constant.route";
import AuthService from "./AuthService";
// import { getScreenResolution } from "../utils/utils";

const API_URL = process.env.REACT_APP_API_URL;
const { REFRESH_TOKEN } = routes.AUTH;

function refreshRequestToken(refreshToken) {
  const refreshTokenBody = {
    token: refreshToken,
    grant_type: "refresh_token"
  };
  return request("POST", `${API_URL}${REFRESH_TOKEN}`, null, refreshTokenBody, null)
    .then(data => {
      // console.log(data.data);
      const response = data.data;

      const obj = {
        accessToken: response.accessToken,
        // accessTokenExpiresAt: response.accessTokenExpiresAt,
        refreshToken: response.newRefreshToken,
        // refreshTokenExpiresAt: response.refreshTokenExpiresAt
      };
      // const userDetail = response.user;
      // console.log("obj", obj);
      // console.log("userDetail", userDetail);
      Storage.setTokenDetail(obj);
      // Storage.setUserDetail(userDetail);
      return Promise.resolve(data);
      // store token to localstorage and send seconde request
    })
    .catch(e => {
      // window.history.replaceState(null, null, '/#/login');
      const err =
        e.response && e.response.data && e.response.data.error[0]
          ? e.response.data.error[0]
          : null;
      const errorName = err ? err.name : "";
      if (errorName === "invalid_grant") {
        Storage.removeKeepMeLoggedInCookie();
        Storage.removeTokenDetail();
        Storage.removeUserDetail();
        window.history.go("/#/login");
        return Promise.reject(e);
      }
      return Promise.reject(e);
      // remove tokens and redirect to logout
    });
}

/**
 * request interceptors
 * @param {String} method GET,PUT,POST,DELETE
 * @param {String} url req url
 * @param {Object} params query parameters
 * @param {Object} body req body
 * @param {Object} headers req headers
 */
export const request = (method, url, params, body = {}, headers = {}) => {
  const token = Storage.getTokenDetail();
  const browser = detect();
  let browserName = browser.name;
  let version = browser.version;
  // let os = browser.os;
  // handle the case where we don't detect the browser
  // if (browser) {
  //   console.log(browser.name);
  //   console.log(browser.version);
  //   console.log(browser.os);
  // }
  headers = headers || {};
  params = params || {};
  body = body || {};

  headers["device_type"] = "browser";
  headers["web_version"] = version;
  headers["browser_name"] = browserName;
  // headers.resolution = getScreenResolution();

  if (!headers["content-type"]) {
    headers["content-type"] = "application/json";
  }

  if (
    !(
      url.includes("login") ||
      url.includes("forgot-password") ||
      url.includes("crm-points/about")
    )
  ) {
    if (token) {
      headers.Authorization = `Bearer ${token.accessToken}`;
    }
    // headers.Authorization = 'Bearer e6ecea97ca8beda3211586f82eb3d065a6579b26';
  } else if (url.includes("login")) {
    headers.Authorization = "Basic amt0eXJlOjEyMw==";
  }

  // if (url.includes('login')) {
  //   body.grant_type = 'password';
  // }

  const options = {
    method,
    headers,
    params,
    url,
  };

  if (
    (method === "POST" || method === "PUT") &&
    headers["content-type"] === "application/x-www-form-urlencoded"
  ) {
    options.data = qs.stringify(body);
  } else if (
    (method === "POST" || method === "PUT") &&
    headers["content-type"] === "multipart/form-data"
  ) {
    headers["content-type"] = "multipart/form-data";

    // prepate multipart formdata body
    const formData = new FormData();
    const keys = Object.keys(body);
    for (let i = 0; i < keys.length; i++) {
      if (keys[i] === "avatar") {
        for (let j = 0; j < body[keys[i]].length; j++) {
          const value = body[keys[i]][j];
          formData.append(keys[i], value);
        }
      } else {
        formData.append(keys[i], body[keys[i]]);
      }
    }
    options.data = formData;
  } else if (method === "POST" || method === "PUT") {
    options.data = body;
  }

  return axios(options)
    .then((data) => {
      return Promise.resolve(data.data);
    })
    .catch((e) => {
      const err = e.response && e.response.data ? e.response.data : null;
      console.log(e);
      console.log("err", err);
      // const err =
      // 	e.response &&
      // 		e.response.data &&
      // 		e.response.data.error &&
      // 		e.response.data.error[0]
      // 		? e.response.data.error[0]
      // 		: null;
      const errorName = err ? err.name : "";
      const preserveRequest = options;
      if (err?.code === "TokenExpiredError") {
        return refreshRequestToken(token.refreshToken)
          .then(data => {
            const accessToken = data.data.accessToken;
            preserveRequest.headers.Authorization = `Bearer ${accessToken}`;
            return axios(preserveRequest);
          })
          .then(data => {
            return Promise.resolve(data.data);
          })
          .catch(e => {
            const err = e.response && e.response.data ? e.response.data : null;
            if (err?.code === "UnAuthorized" && err?.status === false) {
              AuthService.logout();
              window.history.go("/login");
            }
            return Promise.reject(e);
          });
      }
      // if (err?.code === "UnAuthorized" && err?.status === false) {
      //   AuthService.logout();
      //   window.history.go("/login");
      // }
      if (errorName !== "invalid_token") {
        return Promise.reject(e);
      }
    });
};

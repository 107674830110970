import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { setUserDetailAction } from "../../actions/UserAction";

import { notNullValidator } from '../../services/Validation';
import AuthService from '../../services/AuthService';
import logo from '../../assets/images/logo.svg';
import Storage from '../../services/Storage';

const { Group, Control } = Form;
export function Login(props) {

  const dispatch = useDispatch()
  let [isFormValid, setIsFormValid] = useState(false);
  let [errorMessage, setErrorMessage] = useState(null);
  let [redirectToDashboard, setRedirectToDashboard] = useState(false);
  let [keepMeLoggenIn, setKeepMeLoggedIn] = useState(true);
  let [redirectToForgotPassword, setRedirectToForgotPassword] = useState(false);

  let [email, setEmail] = useState({
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
  });

  let [password, setPassword] = useState({
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
  });

  useEffect(() => {
    console.log("props", props)
    if (isAuthenticated()) {
      props.history.push('/dashboard')
    }
  })

  const setEmailData = (control) => {
    console.log("set email data", control.target.value);
    const obj = {
      value: control.target.value,
      valid: null,
      touched: true,
      nullValue: null,
    };
    setErrorMessage(null);
    setEmail(obj);
  };

  const setPasswordData = (control) => {
    console.log("set email data", control.target.value);
    const obj = {
      value: control.target.value,
      valid: null,
      touched: true,
      nullValue: null,
    };
    setErrorMessage(null);
    setPassword(obj);
  };

  const setKeepMeLoggedInInput = (e) => {
    setKeepMeLoggedIn(e.target.checked);
  }
  const isAuthenticated = () => {
    const token = Storage.getTokenDetail();
    if (token) {
      return true;
    }
    return false;
  };

  const handleValidation = () => {
    // let isFormValid = false;
    email = notNullValidator(email);
    console.log("email notNullValidator", email);
    email.valid = !email.nullValue;

    password = notNullValidator(password);
    password.valid = !password.nullValue;

    if (email.valid === true && password.valid === true) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    setEmail({ ...email });
    setPassword({ ...password });
    setIsFormValid(isFormValid);
    return isFormValid;
  };

  const setKeepMeLoggedInValue = (value) => {
    Storage.setKeepMeLoggedInCookie(value);
  }

  const signin = async (event) => {
    try {
      event.preventDefault();
      const isSigninFormValid = handleValidation();
      if (isSigninFormValid === false) {
        return;
      }
      const obj = {
        username: email.value,
        password: password.value,
      };
      const response = await AuthService.signin(obj);
      const {
        data: { accessToken, refreshToken, user },
      } = response;
      if (keepMeLoggenIn === true) {
        setKeepMeLoggedInValue(true);
      } else {
        setKeepMeLoggedInValue(false);
      }
      Storage.setTokenDetail({ accessToken, refreshToken });
      Storage.setUserDetail(user);
      dispatch(setUserDetailAction(user));
      setRedirectToDashboard(true);
      return response;
    } catch (e) {
      const message = e?.response?.data?.message || "Something went wrong";
      setErrorMessage(message);
    }
  };

  return (
    <div>
      {redirectToDashboard && <Redirect to="/dashboard"></Redirect>}
      {redirectToForgotPassword && <Redirect to="/forgot-password"></Redirect>}
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">
            <div className="card text-left py-5 px-4 px-sm-5">
              <div className="brand-logo">
                <img src={logo} alt="logo" />
              </div>
              <h4>Hello! let's get started</h4>
              <h6 className="font-weight-light">Sign in to continue.</h6>
              <Form className="pt-3">
                <Group className="search-field">
                  <Control
                    type="email"
                    placeholder="Username"
                    size="lg"
                    className="h-auto"
                    onChange={setEmailData}
                    value={email.value}
                  />
                  {email.nullValue && (
                    <div class="error">Username should not be empty</div>
                  )}
                </Group>
                <Group className="search-field">
                  {console.log("password", password)}
                  <Control
                    type="password"
                    placeholder="Password"
                    size="lg"
                    className="h-auto"
                    onChange={setPasswordData}
                    value={password.value}
                  />
                  {password.nullValue && (
                    <div class="error">Password should not be empty</div>
                  )}
                </Group>
                <div className="mt-3">
                  <button
                    className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                    onClick={signin}
                  >
                    SIGN IN
                  </button>
                  <div className="error">{errorMessage}</div>
                </div>
                <div className="my-2 d-flex justify-content-between align-items-center">
                  <div className="form-check">
                    <label className="form-check-label text-muted">
                      <input type="checkbox"
                        className="form-check-input"
                        checked={keepMeLoggenIn === true}
                        defaultChecked
                        onChange={setKeepMeLoggedInInput}
                      />
                      <i className="input-helper"></i>
                        Keep me signed in
                      </label>
                  </div>
                  <div onClick={() => setRedirectToForgotPassword(true)} className="auth-link text-muted">Forgot password?</div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;

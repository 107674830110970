import React, { useState, useEffect, useCallback } from "react";
import Switch from "react-switch";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";
import Pagination from "../../component/Pagination/Pagination";
import AddCategory from "../../Modal/AddCategory/AddCategory";
import CategoryService from "../../services/CategoryService";
import Storage from "../../services/Storage";
import CustomSpinner from "../../component/CustomSpinner/CustomSpinner";
import { RECORDS_PER_PAGE } from "../../constants/constant.app";
import { debounce } from "lodash";

// import ReactPaginate from 'react-paginate';

export default function SubCategory(props) {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [subCategories, setSubCategories] = useState([]);
  const [openAddSubCategoryPage, setOpenAddSubCategoryPage] = useState(false);
  const [subCategoryToUpdate, setSubCategoryToUpdate] = useState(null);
  const [search, setSearch] = useState("");
  const [active, setActive] = useState("");
  // const [active, setActive] = useState("");
  // const [userDetail, setUserDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const pageRange = 3;
  const marginPagesDisplayed = 2;
  // const [showAlert, setShowAlert] = useState(false);
  // const [deleteIndex, setDeleteIndex] = useState(null);

  useEffect(() => {
    if (props.categoryId) {
      getSubCategoryList(1);
    } else {
      setSubCategories([])
    }
  }, [props.categoryId]);

  // useEffect(() => {
  //   // debounce(getEvents, 0.5);
  //   getSubCategoryList(1);
  // }, [search]);

  useEffect(() => {
    debouncedChangeHandler(search);
  }, [search]);

  const searchCategoryList = (search) => {
    setPage(1);
    getSubCategoryList(1, null, search);
  };

  const debouncedChangeHandler = useCallback(
    debounce(searchCategoryList, 1000),
    []
  );

  const openDeleteCategoryPopup = async (index) => {
    // setShowAlert(true);
    // setDeleteIndex(index);
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this imaginary file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
      background: "#191c24",
    });
    if (result.isConfirmed) {
      deleteCategory(index);
    } else if (result.dismiss === Swal.DismissReason.cancel) {
    }
  };

  const deleteCategory = async (index) => {
    // setShowAlert(false);
    const subCategory = subCategories[index];
    const deleteCategoryObj = {
      categoryId: subCategory.categoryId,
      isDeleted: true,
    };
    setIsLoading(true);
    try {
      await CategoryService.updateSubCategory(deleteCategoryObj);
      const message = "Category deleted successfully";
      setIsLoading(false);
      getSubCategoryList(1, null, search);
      setPage(1);
      await Swal.fire({
        title: "Delete category",
        text: message,
        icon: "success",
        confirmButtonText: "Ok!",
        background: "#191c24",
      });
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: "Delete categories",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    }
  };

  const onPageChange = (page) => {
    getSubCategoryList(page, null, search);
    setPage(page);
  };

  const getSubCategoryList = async (page, active = null, search) => {
    try {
      const getCategoryObj = {
        page,
        limit: RECORDS_PER_PAGE,
        parentCategoryId: props.categoryId,
      };

      if (active === "true") {
        getCategoryObj.isActive = true;
      } else if (active === "false") {
        getCategoryObj.isActive = false;
      }

      if (search) {
        getCategoryObj.search = search;
      }
      if (props.categoryId) {
        setIsLoading(true);
        const categoryFromService = await CategoryService.listCategory(
          getCategoryObj
        );
        const {
          data: { data, totalPages },
        } = categoryFromService;
        setPageCount(totalPages);
        setSubCategories([...data]);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: "List categories",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    }
  };

  const inactiveCategory = async (index) => {
    const subCategory = subCategories[index];
    subCategory.isActive = !subCategory.isActive;
    await updateSubCategory(subCategory);
    setSubCategories([...subCategories]);
  };

  const updateSubCategory = async (category) => {
    const body = {
      categoryId: category.categoryId,
      name: category.name,
      isActive: category.isActive,
    };
    try {
      setIsLoading(true);
      await CategoryService.updateSubCategory(body);
      const message = "Category updated successfully";
      setIsLoading(false);
      await Swal.fire({
        title: "Update category",
        text: message,
        icon: "success",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: "Update category",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    }
  };

  const openUpdateCategoryModal = (category) => {
    setSubCategoryToUpdate({ ...category });
    setOpenAddSubCategoryPage(!openAddSubCategoryPage);
  };

  const closeAddSubCategoryModal = () => {
    setOpenAddSubCategoryPage(!openAddSubCategoryPage);
    setSubCategoryToUpdate(null);
    getSubCategoryList(1, active, search);
    setPage(1);
  };

  const resetFilter = async () => {
    setActive("");
    getSubCategoryList(1, "", search);
  };

  // const resetFilter = async () => {
  //   setActive("");
  //   getSubCategoryList(1, "");
  // }
  return (
    // <div className="position-relative">
    <div className="card">
      {isLoading && <CustomSpinner></CustomSpinner>}
      {/* <div class="page-header">
        <h3 class="page-title"> Categories </h3>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">
              <button
                class="btn btn-primary"
                onClick={() => setOpenAddSubCategoryPage(!openAddSubCategoryPage)}
              >
                Add Category
              </button>
            </li>
          </ol>
        </nav>
      </div> */}
      {openAddSubCategoryPage && (
        <AddCategory
          show={openAddSubCategoryPage}
          category={subCategoryToUpdate}
          closeModal={closeAddSubCategoryModal}
          module="subcategory"
        ></AddCategory>
      )}
      <div className="card-body" style={{ width: "100%" }}>
        <div className="row" style={{ marginBottom: "10px" }}>
          <div className="col-6">
            <h4>Subcategories</h4>
          </div>
          <div className="col-6" style={{ textAlign: "right" }}>
            {/* <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item active" aria-current="page"> */}
            <button
              class="btn btn-primary"
              onClick={() => setOpenAddSubCategoryPage(!openAddSubCategoryPage)}
            >
              Add Subcategory
            </button>
            {/* </li>
                    </ol>
                  </nav> */}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <Form.Group>
              <label htmlFor="exampleInputPassword1">Search</label>
              <Form.Control
                type="text"
                id="search"
                placeholder="Search.."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <Form.Group>
              <label htmlFor="type">Active</label>
              <div>
                <select
                  className="form-control"
                  onChange={(e) => setActive(e.target.value)}
                  value={active}
                >
                  <option value="">All</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            </Form.Group>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6" style={{ margin: "auto 0px" }}>
            <button
              class="btn btn-primary"
              onClick={() => getSubCategoryList(1, active, search)}
            >
              Apply
            </button>
            &nbsp;&nbsp;
            <button
              type="button"
              className="btn btn-warning btn-icon-text"
              onClick={resetFilter}
            >
              Reset
            </button>
          </div>
          {/* <div className="col-3">
                  <Form.Group>
                    <label htmlFor="type">Active</label>
                    <div>
                      <select
                        className="form-control"
                        onChange={(e) => setActive(e.target.value)}
                        value={active}
                      >
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </Form.Group>
                </div> */}
          {/* <div className="col-3" style={{ margin: "auto 0px" }}>
                  <button class="btn btn-primary" onClick={() => getSubCategoryList(1)}>
                    Apply
                  </button>&nbsp;&nbsp;
                  <button type="button" className="btn btn-warning btn-icon-text" onClick={resetFilter}>
                    Reset
                  </button>
                </div> */}
        </div>
        {/* <p className="card-description"> Add className <code>.table</code> */}
        {/* </p> */}
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th style={{ minWidth: '150px' }}>Name</th>
                <th>Active</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {subCategories.map((c, index) => (
                <tr>
                  <td class="">{c.name}</td>
                  <td>
                    <Switch
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onColor="#0090e7"
                      height={20}
                      width={40}
                      onChange={() => inactiveCategory(index)}
                      checked={c.isActive}
                    />
                  </td>
                  <td>
                    <i
                      className="mdi mdi-table-edit"
                      style={{
                        cursor: "pointer",
                        fontSize: "20px",
                        color: "#0090e7",
                      }}
                      title="Edit event"
                      onClick={() => openUpdateCategoryModal(c)}
                    ></i>
                    &nbsp;&nbsp;
                    <i
                      className="mdi mdi-delete"
                      style={{
                        cursor: "pointer",
                        fontSize: "20px",
                        color: "#0090e7",
                      }}
                      title="Delete event"
                      onClick={() => openDeleteCategoryPopup(index)}
                    ></i>
                  </td>
                </tr>
              ))}
              {subCategories && subCategories.length === 0 && (
                <tr>
                  <td colSpan="3" style={{ textAlign: "center" }}>
                    No subcategories available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {pageCount > 1 && (
            <Pagination
              totalRecords={pageCount * RECORDS_PER_PAGE}
              itemsCountPerPage={RECORDS_PER_PAGE}
              page={page}
              pageCount={pageCount}
              pageRangeDisplayed={pageRange}
              marginPagesDisplayed={marginPagesDisplayed}
              onPageChange={onPageChange}
            ></Pagination>
          )}
        </div>
      </div>
    </div>
  );
}

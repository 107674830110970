import React, { useEffect, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import Switch from "react-switch";
import { useDispatch } from "react-redux";
import { setUserDetailAction } from "../../actions/UserAction";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
// import Loader from "react-loader-spinner";
import {
  validatePhoneNumber,
  emailValidator,
  notNullValidator,
  maxLengthValidator,
} from "../../services/Validation";
import UserService from "../../services/UserService";
import TimezoneService from "../../services/TimezoneService";
import CountryService from "../../services/CountryService";
import { S3_BUCKET_URL } from "../../constants/constant.app";
import "react-datepicker/dist/react-datepicker.css";
import Storage from "../../services/Storage";

const controlObj = {
  value: "",
  valid: null,
  touched: false,
  nullValue: null,
};
// const s3BucketUrl = `http://s3.me-south-1.amazonaws.com/stayactive-development/`;

export default function AddEditUser(props) {
  const dispatch = useDispatch();
  const nameRef = useRef();
  // const [value, onChange] = useState(new Date());
  // const [hours, setHours] = useState(0);
  // const [minutes, setMinutes] = useState(0);
  const [userId, setUserId] = useState(null);
  const [roles, setRoles] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const [countries, setCountries] = useState([]);
  let [isFormValid, setIsFormValid] = useState(false);
  let [name, setName] = useState({ ...controlObj });
  let [email, setEmail] = useState({ ...controlObj });
  let [address, setAddress] = useState({ ...controlObj });
  let [phone, setPhone] = useState({ ...controlObj });
  let [bio, setBio] = useState({ ...controlObj });
  let [country, setCountry] = useState({ ...controlObj });
  let [gender, setGender] = useState({ ...controlObj, value: "male" });
  let [role, setRole] = useState({ ...controlObj });
  let [dateOfBirth, setDateOfBirth] = useState({ ...controlObj });
  let [profession, setProfession] = useState({ ...controlObj });
  let [profile, setProfile] = useState({ ...controlObj, value: "public" });
  let [lat, setLat] = useState({ ...controlObj });
  let [long, setLong] = useState({ ...controlObj });
  let [timezone, setTimezone] = useState({ ...controlObj });
  let [avatar, setAvatar] = useState(null);
  let [profileImage, setProfileImage] = useState(null);
  let [isActive, setIsActive] = useState(true);
  let [currentUserDetail, setCurrentUserDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const userData = props?.user || props?.location?.state?.user;
    const currentUserDetail = props?.currentUserDetail || props?.location?.state?.currentUserDetail;
    setCurrentUserDetail(currentUserDetail);
    if (roles.length === 0) {
      getRoles();
    }
    if (timezones.length === 0) {
      getTimezones();
    }
    if (countries.length === 0) {
      getCountries();
    }
    if (userData) {
      const {
        userId,
        roleId,
        avatarPath,
        isActive,
        // avatarName,
        // avatarWidth,
        // avatarHeight,
        name: nameFromDb,
        email: emailFromDb,
        address: addressFromDb,
        phone: phoneFromDb,
        bio: bioFromDb,
        // country: countryFromDb,
        countryId: countryIdFromDb,
        gender: genderFromDb,
        dateOfBirth: dob,
        profession: professionFromDb,
        userLat,
        userLong,
        isPublicProfile,
        // timezone: timezoneFromDb,
        timezoneId: timezoneIdFromDb,
      } = userData;
      setUserId(userId);
      setName(() => setInitialInputValue(nameFromDb, name));
      setEmail(() => setInitialInputValue(emailFromDb, email));
      setAddress(() => setInitialInputValue(addressFromDb, address));
      setPhone(() => setInitialInputValue(phoneFromDb, phone));
      setBio(() => setInitialInputValue(bioFromDb, bio));
      setCountry(() => setInitialInputValue(countryIdFromDb, country));
      setGender(() => setInitialInputValue(genderFromDb, gender));
      setRole(() => setInitialInputValue(roleId, role));
      setDateOfBirth(() =>
        setInitialInputValue(dob ? new Date(dob) : null, dateOfBirth)
      );
      setProfession(() => setInitialInputValue(professionFromDb, profession));
      setLat(() => setInitialInputValue(userLat, lat));
      setLong(() => setInitialInputValue(userLong, long));
      setProfile(() =>
        setInitialInputValue(
          isPublicProfile === true ? "public" : "private",
          profile
        )
      );
      // setTimezone(() => setInitialInputValue(timezoneFromDb, timezone));
      setTimezone(() => setInitialInputValue(timezoneIdFromDb, timezone));
      // setCou(() => setInitialInputValue(timezoneFromDb, timezone));
      setIsActive(isActive);
      setProfileImage(avatarPath);
    }
    // if (nameRef) {
    //   nameRef.current.focus()
    // }
  }, [props.user]);

  function setInitialInputValue(value, input) {
    if (!value) {
      input.value = "";
    } else {
      input.value = value;
    }
    return { ...input };
  }

  const setInputValue = (e, input) => {
    const value = e.target.value;
    input.touched = true;
    input.value = value;
    console.log("input", input);
    return { ...input };
  };

  const setNameInput = (e) => {
    setName(() => setInputValue(e, name));
  };
  const setEmailInput = (e) => {
    setEmail(() => setInputValue(e, email));
  };
  const setAddressInput = (e) => {
    setAddress(() => setInputValue(e, address));
  };
  const setPhoneInput = (e) => {
    setPhone(() => setInputValue(e, phone));
  };
  const setGenderInput = (e) => {
    setGender(() => setInputValue(e, gender));
  };
  const setRoleInput = (e) => {
    console.log("r.target.valye", e.target.value);
    setRole(() => setInputValue(e, role));
  };
  const setCountryInput = (e) => {
    setCountry(() => setInputValue(e, country));
  };
  const setBioInput = (e) => {
    setBio(() => setInputValue(e, bio));
  };
  const setProfessionInput = (e) => {
    setProfession(() => setInputValue(e, profession));
  };
  const setProfileInput = (e) => {
    setProfile(() => setInputValue(e, profile));
  };
  const setLatInput = (e) => {
    setLat(() => setInputValue(e, lat));
  };
  const setLongInput = (e) => {
    setLong(() => setInputValue(e, long));
  };
  const setTimezoneInput = (e) => {
    setTimezone(() => setInputValue(e, timezone));
  };
  const setDateOfBirthInput = (date) => {
    dateOfBirth.touched = true;
    dateOfBirth.value = date;
    setDateOfBirth({ ...dateOfBirth });
  };

  const handleValidation = () => {
    // let isFormValid = false;
    name = notNullValidator(name);
    name.valid = !name.nullValue;

    email = notNullValidator(email);
    email = emailValidator(email);
    email.valid = !email.nullValue || !email.invalidEmail;

    address = notNullValidator(address);
    address.valid = !address.nullValue;

    phone = validatePhoneNumber(phone);
    phone = maxLengthValidator(phone, 9);
    phone.valid = !phone.invalidPhone && !phone.invalidLength;

    bio = notNullValidator(bio);
    bio.valid = !bio.nullValue;

    role = notNullValidator(role);
    role.valid = !role.nullValue;
    // dateOfBirth = notNullValidator(dateOfBirth);
    // dateOfBirth.valid = !dateOfBirth.nullValue;

    // profession = notNullValidator(profession);
    // profession.valid = !profession.nullValue;

    profile = notNullValidator(profile);
    profile.valid = !profile.nullValue;

    // lat = notNullValidator(lat);
    // lat.valid = !lat.nullValue;

    // long = notNullValidator(long);
    // long.valid = !long.nullValue;

    timezone = notNullValidator(timezone);
    timezone.valid = !timezone.nullValue;

    country = notNullValidator(country);
    country.valid = !country.nullValue;

    let isFormValid =
      name.valid === true &&
      email.valid === true &&
      bio.valid === true &&
      profile.valid === true &&
      timezone.valid === true &&
      country.valid === true &&
      role.valid === true;

    if (!props.isCurrentUser) {
      isFormValid =
        isFormValid &&
        address.valid === true &&
        phone.valid === true
    }
    setName({ ...name });
    setEmail({ ...email });
    setAddress({ ...address });
    setPhone({ ...phone });
    setBio({ ...bio });
    setDateOfBirth({ ...dateOfBirth });
    setProfession({ ...profession });
    setProfile({ ...profile });
    setCountry({ ...country });
    setIsFormValid(isFormValid);
    return isFormValid;
  };

  const closeModal = () => {
    setIsFormValid(false);
    setName({ ...controlObj });
    setEmail({ ...controlObj });
    setAddress({ ...controlObj });
    setPhone({ ...controlObj });
    setBio({ ...controlObj });
    setCountry({ ...controlObj });
    setGender({ ...controlObj });
    setDateOfBirth({ ...controlObj });
    setProfession({ ...controlObj });
    setProfile({ ...controlObj, value: "public" });
    setLat({ ...controlObj });
    setLong({ ...controlObj });
    setUserId(null);
    setTimezone({ ...controlObj, value: "asia/kuwait" });
    if (roles.length > 0) {
      setRole({ ...controlObj, value: roles[0].roleId });
    }
    setProfileImage(null);
    setAvatar(null);
    setIsActive(true);
    if (props.type === "modal") {
      props.closeModal();
    } else if (props.type === "component") {
      // do nothing
    } else {
      props.history.push({
        pathname: "/users",
      });
    }
  };

  const removeUploadedImage = async () => {
    setAvatar(null);
  };

  const handleUploadImage = async (event) => {
    const file = event.target.files[0];
    setProfileImage(null);
    setAvatar(file);
  };

  const removeProfileImage = async (index) => {
    setProfileImage(null);
  };

  const addUser = async (event) => {
    try {
      event.preventDefault();
      console.log("profile", profile, role, timezone);
      const isUserFormValid = handleValidation();
      console.log("isUserFormValid", isUserFormValid);
      if (isUserFormValid === false) {
        return;
      }

      let obj = {
        name: name.value,
        email: email.value,
        bio: bio.value,
        country: country.value,
        profile: profile.value,
        timezone: timezone.value,
        roleId: role.value,
      };
      if (currentUserDetail.userId !== userId) {
        obj = {
          ...obj,
          address: address.value,
          phone: phone.value,
          gender: gender.value,
          dateOfBirth: dateOfBirth.value,
          profession: profession.value,
          // userLat: lat.value,
          // userLong: long.value,
          isActive,
        };
      }
      if (avatar) {
        obj.avatar = [avatar];
      }
      if (!avatar && !profileImage) {
        obj.removeProfileImage = true;
      }

      setIsLoading(true);
      if (userId) {
        obj.userId = userId;
        const updateResponse = await UserService.updateUserByAdmin(obj);
        if (currentUserDetail.userId === userId) {
          const userDetail = updateResponse.data;
          Storage.setUserDetail(userDetail);
          dispatch(setUserDetailAction(userDetail));
        }
      } else {
        await UserService.addUserByAdmin(obj);
      }
      setIsLoading(false);
      const message = userId
        ? "User updated successfully"
        : "User added successfully";
      await Swal.fire({
        title: userId ? "Edit User" : "Add User",
        text: message,
        icon: "success",
        confirmButtonText: "Ok!",
        background: "#191c24",
      });
      if (props.type !== "component") {
        closeModal();
      }
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: userId ? "Edit User" : "Add User",
        text: message,
        icon: "error",
        confirmButtonText: "Ok!",
        background: "#191c24",
      });
    }
  };

  const getRoles = async () => {
    const userData = props?.user || props?.location?.state;
    const data = await UserService.getRoles();
    setRoles([...data.data]);
    if (!userData && data.data.length > 0) {
      setRole(() => setInitialInputValue(data.data[0].roleId, role));
    }
  };

  const getTimezones = async () => {
    try {
      const timezones = await TimezoneService.getTimezones();
      setTimezones(timezones.data);
    } catch (e) { }
  };

  const getCountries = async () => {
    try {
      const countries = await CountryService.getCountries();
      setCountries(countries.data);
    } catch (e) { }
  };

  return (
    <div style={{ width: '100%' }}>
      {!["modal", "component"].includes(props.type) && (
        <div class="page-header">
          <h3 class="page-title">User</h3>
        </div>
      )}
      {isLoading && <CustomSpinner></CustomSpinner>}
      {/* <Loader
        className="loader"
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        visible //3 secs
      /> */}
      <div className="card">
        <div className="card-body">
          {props.type === "modal" && (
            <h4 className="card-title">
              {userId ? "Update user" : "Add User"}
            </h4>
          )}
          {/* <p className="card-description"> Basic form layout </p> */}
          <form className="forms-sample">
            <Form.Group>
              <label>Profile image</label>
              <div class="custom-file">
                <input
                  lang="es"
                  type="file"
                  id="customFileLang"
                  class="form-control visibility-hidden form-control-file"
                  onChange={handleUploadImage}
                  accept="image/*"
                />
                <label class="custom-file-label" for="customFileLang">
                  Upload image
                </label>
              </div>
            </Form.Group>
            {(avatar || profileImage) && (
              <div class="form-group">
                <div className="row">
                  <div className="col-md">
                    <div className="row no-gutters">
                      {avatar && (
                        <div className="col-sm-4 app-img">
                          <img
                            src={URL.createObjectURL(avatar)}
                            alt="profile"
                            className="img-fluid"
                          ></img>
                          <button
                            type="button"
                            class="close-button"
                            onClick={() => removeUploadedImage()}
                          ></button>
                        </div>
                      )}
                      {profileImage && (
                        <div className="col-sm-4 app-img">
                          <img
                            src={`${S3_BUCKET_URL}${profileImage}`}
                            alt="profile"
                            className="img-fluid"
                          ></img>
                          <button
                            type="button"
                            class="close-button"
                            onClick={() => removeProfileImage()}
                          ></button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* {!avatar && profileImage && (
              <div>
                <img
                  src={`${S3_BUCKET_URL}${profileImage}`}
                  alt="avatar"
                  className="event-img"
                ></img>
                &nbsp;
                <span onClick={() => removeProfileImage()}>
                  <i
                    className="mdi mdi-close-circle-outline"
                    style={{ cursor: "pointer" }}
                  ></i>
                </span>
              </div>
            )} */}
            <Form.Group>
              <label htmlFor="name">
                Name <span className="required">*</span>
              </label>
              <Form.Control
                type="text"
                id="name"
                value={name.value}
                onChange={setNameInput}
                ref={nameRef}
              />
              {name.nullValue && (
                <div className="error">Name should not empty</div>
              )}
            </Form.Group>
            <Form.Group>
              <label htmlFor="email">
                Email <span className="required">*</span>
              </label>
              <Form.Control
                type="text"
                className="form-control"
                id="email"
                value={email.value}
                onChange={setEmailInput}
                disabled={userId}
              />
              {email.nullValue && (
                <div className="error">Email should not empty</div>
              )}
              {email.invalidEmail && (
                <div className="error">Please enter valid email</div>
              )}
            </Form.Group>

            {/* <div className="row"> */}
            {/* </div> */}
            {currentUserDetail.userId !== userId && (
              <Form.Group>
                <label htmlFor="phone">Phone</label>
                <Form.Control
                  type="text"
                  id="phone"
                  value={phone.value}
                  onChange={setPhoneInput}
                />
                {phone.nullValue && (
                  <div className="error">Phone should not empty</div>
                )}
                {phone.invalidPhone && (
                  <div className="error">Please enter valid number</div>
                )}
                {phone.invalidLength && (
                  <div className="error">phone should not contain more than 9 numbers</div>
                )}
              </Form.Group>
            )}

            {currentUserDetail.userId !== userId && (
              <Form.Group>
                <label htmlFor="address">
                  Address <span className="required">*</span>
                </label>
                <Form.Control
                  type="text"
                  id="address"
                  value={address.value}
                  onChange={setAddressInput}
                />
                {address.nullValue && (
                  <div className="error">Address should not empty</div>
                )}
              </Form.Group>
            )}

            <Form.Group>
              <label htmlFor="bio">
                Bio <span className="required">*</span>
              </label>
              <Form.Control
                type="text"
                id="bio"
                value={bio.value}
                onChange={setBioInput}
              />
              {bio.nullValue && (
                <div className="error">Bio should not empty</div>
              )}
            </Form.Group>

            <div className="row">
              {/* <Form.Group className="col-6"> */}
              <Form.Group className="col-6">
                <label htmlFor="Country">
                  Country <span className="required">*</span>
                </label>
                <div>
                  <select
                    className="form-control"
                    onChange={setCountryInput}
                    value={country.value}
                  >
                    <option value={""}>Select</option>
                    {countries.map((c) => (
                      <option value={c.countryId}>{c.name}</option>
                    ))}
                    {/* <option value="kuwait">Kuwait</option> */}
                  </select>
                </div>
                {country.nullValue && (
                  <div className="error">Please select country</div>
                )}
              </Form.Group>
              {currentUserDetail.userId !== userId && (
                <Form.Group className="col-6">
                  <label htmlFor="gender">Gender</label>
                  <div>
                    <select
                      className="form-control"
                      onChange={setGenderInput}
                      value={gender.value}
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                </Form.Group>
              )}
            </div>
            {currentUserDetail.userId !== userId && (
              <Form.Group>
                <label htmlFor="birthdate">Birth Date</label>
                <DatePicker
                  selected={dateOfBirth.value}
                  onChange={(date) => setDateOfBirthInput(date)}
                  // showTimeSelect
                  // filterTime={filterPassedTime}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="MMMM d, yyyy"
                  maxDate={new Date()}
                  isClearable
                />
                {dateOfBirth.nullValue && (
                  <div className="error">Birth date should not empty</div>
                )}
              </Form.Group>
            )}
            {currentUserDetail.userId !== userId && (
              <Form.Group>
                <label htmlFor="profession">Profession</label>
                <Form.Control
                  type="text"
                  id="profession"
                  value={profession.value}
                  onChange={setProfessionInput}
                />
                {profession.nullValue && (
                  <div className="error">Profession should not empty</div>
                )}
              </Form.Group>
            )}
            <div className="row">
              <Form.Group onChange={setProfileInput} className="col-6">
                <label htmlFor="profile" style={{ display: "block" }}>
                  Profile <span className="required">*</span>
                </label>
                <span className="form-check">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="optionsRadios"
                      id="optionsRadios1"
                      value="private"
                      checked={profile.value === "private"}
                    />
                    <i className="input-helper"></i> Private
                  </label>
                </span>
                &nbsp;&nbsp;
                <span className="form-check">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="optionsRadios"
                      id="optionsRadios2"
                      value="public"
                      checked={profile.value === "public"}
                    />
                    <i className="input-helper"></i> Public
                  </label>
                </span>
                {/* <Form.Control
											type="text"
											id="profile"
											placeholder="profile"
											value={profile.value}
											onChange={setProfileInput}
										/> */}
                {profile.nullValue && (
                  <div className="error">Profile should not empty</div>
                )}
              </Form.Group>
              <Form.Group className="col-6">
                <label htmlFor="role">
                  Role <span className="required">*</span>
                </label>
                <div>
                  <select
                    className="form-control"
                    onChange={setRoleInput}
                    value={role.value}
                    disabled={currentUserDetail.userId === userId}
                  >
                    <option value={""}>Select</option>
                    {roles.map((r) => (
                      <option value={r.roleId}>{r.name}</option>
                    ))}
                  </select>
                </div>
                {role.nullValue && (
                  <div className="error">Role should not empty</div>
                )}
              </Form.Group>
            </div>
            {/* {!props.isCurrentUser && (
              <div className="row">
                <Form.Group className="col-6">
                  <label htmlFor="Latitude">
                    Latitude <span className="required">*</span>
                  </label>
                  <Form.Control
                    type="number"
                    id="Latitude"
                    value={lat.value}
                    onChange={setLatInput}
                  />
                  {lat.nullValue && (
                    <div className="error">latitude should not empty</div>
                  )}
                </Form.Group>
                <Form.Group className="col-6">
                  <label htmlFor="Longitude">
                    Longitude <span className="required">*</span>
                  </label>
                  <Form.Control
                    type="number"
                    id="Longitude"
                    value={long.value}
                    onChange={setLongInput}
                  />
                  {long.nullValue && (
                    <div className="error">longitude should not empty</div>
                  )}
                </Form.Group>
              </div>
            )} */}
            <div className="row">
              <Form.Group className="col-6">
                <label htmlFor="Timezone">
                  Timezone <span className="required">*</span>
                </label>
                <div>
                  <select
                    className="form-control"
                    onChange={setTimezoneInput}
                    value={timezone.value}
                  >
                    <option value={""}>Select</option>
                    {timezones.map((t) => (
                      <option value={t.timezoneId}>{t.zone}</option>
                    ))}
                  </select>
                </div>
                {timezone.nullValue && (
                  <div className="error">Please select timezone</div>
                )}
              </Form.Group>
            </div>
            {currentUserDetail.userId !== userId && (
              <div className="row">
                <Form.Group className="col-6">
                  <Switch
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onColor="#0090e7"
                    height={20}
                    width={40}
                    onChange={() => setIsActive(!isActive)}
                    checked={isActive}
                  />
                  <label
                    htmlFor="Active"
                    style={{ paddingLeft: "10px", lineHeight: "21px" }}
                  >
                    Active
                  </label>
                  {/* <Form.Control type="text" id="Trending" placeholder="Trending" /> */}
                </Form.Group>
              </div>
            )}
            {/* <div className="form-check">
										<label className="form-check-label text-muted">
											<input type="checkbox" className="form-check-input" />
											<i className="input-helper"></i>
                      Remember me
                    </label>
									</div> */}
            <button
              type="submit"
              className="btn btn-primary mr-2"
              onClick={addUser}
            >
              Submit
            </button>

            {props.type !== "component" && (
              <button className="btn btn-dark" onClick={closeModal}>
                Cancel
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

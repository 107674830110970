import React from 'react';
import PaymentDetailComponent from '../../component/PaymentDetailComponent/PaymentDetailComponent';

export default function PaymentDetail(props) {
  const closeModal = () => {
    props.closeModal();
  }

  return <div class="modal" tabindex="-1" role="dialog" style={{ display: props.show === true ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.6)' }}>
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <PaymentDetailComponent
            PaymentDetail={props.paymentDetail}
            closeModal={closeModal}
            type='modal'
          ></PaymentDetailComponent>
        </div>
      </div>
    </div>
  </div>
}
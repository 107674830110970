import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import {
  notNullValidator,
  validatePositiveNumber,
} from "../../services/Validation";
import PostService from "../../services/PostService";
import CategoryService from "../../services/CategoryService";
// import SubCategoryService from "../../services/SubCategoryService";
// import PaymentService from "../../services/PaymentService";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import "react-datepicker/dist/react-datepicker.css";
import { S3_BUCKET_URL } from "../../constants/constant.app";

const controlObj = {
  value: "",
  valid: null,
  touched: false,
  nullValue: null,
};
export default function AddPost(props) {
  // const [value, onChange] = useState(new Date());
  // const [hours, setHours] = useState(0);
  // const [minutes, setMinutes] = useState(0);
  let [isFormValid, setIsFormValid] = useState(false);
  let [title, setTitle] = useState({ ...controlObj });
  let [postId, setPostId] = useState(null);
  let [description, setDescription] = useState({ ...controlObj });
  let [category, setCategory] = useState({ ...controlObj, name: "category" });
  let [subCategory, setSubCategory] = useState({ ...controlObj });
  let [active, setActive] = useState(true);
  let [lat, setLat] = useState({ ...controlObj });
  let [long, setLong] = useState({ ...controlObj });
  let [categories, setCategories] = useState([]);
  let [subCategories, setSubCategories] = useState([]);
  let [avatar, setAvatar] = useState(null);
  let [posters, setPosters] = useState([]);
  let [removedImages, setremovedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const postData = props?.post || props?.location?.state;
    let categoryList = null;
    async function getInitialData() {
      categoryList = await getCategoryList();
      if (!postData) {
        setCategory(() =>
          setInitialInputValue(categoryList[0].categoryId, category)
        );
        const subCategoryList = await getSubCategoryList(
          categoryList[0].categoryId
        );
        setSubCategory(() =>
          setInitialInputValue(subCategoryList[0].categoryId, subCategory)
        );
      }
    }
    getInitialData();
    if (postData) {
      const {
        postId,
        title: postTitle,
        description: postDescription,
        category: postCategory,
        subCategory: postSubCategory,
        price: postPrice,
        postLat,
        postLong,
        postPosters,
        isActive
      } = postData;
      console.log("postCategory", postCategory)

      // listEventMembers(eventId);
      setTitle(() => setInitialInputValue(postTitle, title));
      setDescription(() => setInitialInputValue(postDescription, description));
      setCategory(() =>
        setInitialInputValue(postCategory.categoryId, category)
      );
      setSubCategory(() =>
        setInitialInputValue(postSubCategory.categoryId, subCategory)
      );
      setLat(() => setInitialInputValue(postLat, lat));
      setLong(() => setInitialInputValue(postLong, long));
      getSubCategoryList(postCategory.categoryId);
      setPostId(postId);
      setActive(isActive);
      console.log("postPosters", postPosters)
      setPosters(() => [...postPosters]);
    }
  }, [props.post]);

  // useEffect(() => {
  // 	async function setSubCategoryOnCategoryChange() {
  // 		console.log("category.value", category.value);
  // 		const subCategories = await getSubCategoryList(category.value);
  // 		console.log("subCategories", subCategories);
  // 		setSubCategory(() => setInputValue(subCategories[0].subCategoryId, subCategory));
  // 	}
  // 	setSubCategoryOnCategoryChange();
  // }, [category]);

  function setInitialInputValue(value, input) {
    input.value = value;
    return { ...input };
  }
  const setInputValue = (e, input) => {
    const value = e.target.value;
    input.touched = true;
    input.value = value;
    console.log("input", input);
    return { ...input };
  };
  const setTitleInput = (e) => {
    console.log("e", e);
    setTitle(() => setInputValue(e, title));
  };
  const setDescriptionInput = (e) => {
    setDescription(() => setInputValue(e, description));
  };
  const setCategoryInput = async (e) => {
    setCategory(() => setInputValue(e, category));
    const subCategyList = await getSubCategoryList(e.target.value);
    if (subCategyList[0]) {
      setSubCategory(() =>
        setInitialInputValue(subCategyList[0].categoryId, subCategory)
      );
    }
  };

  const setSubCategoryInput = (e) => {
    setSubCategory(() => setInputValue(e, subCategory));
  };

  // const setLatInput = (e) => {
  //   setLat(() => setInputValue(e, lat));
  // };
  // const setLongInput = (e) => {
  //   setLong(() => setInputValue(e, long));
  // };
  const handleUploadImage = async (event) => {
    const file = event.target.files;
    // console.log("event", event.target.files[0]);
    setAvatar([...file]);
    // setAvatarUrl(URL.createObjectURL(file));
    console.log("file", file);
  };

  const removePosterImage = async (index) => {
    removedImages.push(posters[index].postPosterId);
    posters.splice(index, 1);
    setremovedImages([...removedImages]);
    setPosters([...posters]);
  };

  const removeUploadedPosterImage = async (index) => {
    // removedImages.push(posters[index].posterId);
    avatar.splice(index, 1);
    setAvatar([...avatar]);
  };

  const handleValidation = () => {
    // let isFormValid = false;
    title = notNullValidator(title);
    title.valid = !title.nullValue;

    description = notNullValidator(description);
    description.valid = !description.nullValue;


    category = notNullValidator(category);
    category.valid = !category.nullValue;

    subCategory = notNullValidator(subCategory);
    subCategory.valid = !subCategory.nullValue;

    // lat = notNullValidator(lat);
    // lat.valid = !lat.nullValue;

    // long = notNullValidator(long);
    // long.valid = !long.nullValue;

    if (
      title.valid === true &&
      description.valid === true &&
      category.valid === true &&
      subCategory.valid === true
      // lat.valid === true &&
      // long.valid === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    setTitle({ ...title });
    setDescription({ ...description });
    // setLat({ ...lat });
    // setLong({ ...long });
    setIsFormValid(isFormValid);
    return isFormValid;
  };

  const getCategoryList = async () => {
    const categories = await CategoryService.listCategory();
    const {
      data: { data },
    } = categories;
    setCategories([...data]);
    return data;
  };

  // const listEventMembers = async (eventId) => {
  //   const members = await PaymentService.listPayment();
  //   // const members = await PostService.listEventMembers(eventId);
  //   const {
  //     data: { data },
  //   } = members;
  //   console.log("data", data);
  //   // setCategories([...data]);
  //   return data;
  // };


  const getSubCategoryList = async (categoryId) => {
    const body = {
      parentCategoryId: categoryId,
      page: 1,
      limit: 10000
    }
    const subCategories = await CategoryService.listCategory(body);
    const {
      data: { data },
    } = subCategories;
    setSubCategories([...data]);
    return data;
  };

  const closeAddPost = () => {
    setIsFormValid(false);
    setTitle({ ...controlObj });
    setDescription({ ...controlObj });
    setCategory({ ...controlObj });
    setSubCategory({ ...controlObj });
    setActive(true);
    setLat({ ...controlObj });
    setLong({ ...controlObj });
    setAvatar(null);
    setPosters([]);
    setremovedImages([]);
    setPostId(null);
    if (props.type === "modal") {
      closeAddPost();
    } else {
      props.history.push({
        pathname: "/post",
      });
    }
  };

  const addPost = async (event) => {
    try {
      event.preventDefault();
      console.log("event detail", event);
      const isPostFormValid = handleValidation();
      console.log("isPostFormValid", isPostFormValid);
      if (isPostFormValid === false) {
        return;
      }

      const obj = {
        title: title.value,
        description: description.value,
        categoryId: category.value,
        subCategoryId: subCategory.value,
        isActive: active,
        memberData: "[]",
        inclusion: null,
        exclusion: null,
        removedImages: "[]",
      };
      console.log(avatar);
      if (avatar) {
        obj.avatar = avatar;
      }
      setIsLoading(true);
      if (postId) {
        obj.id = postId;
        if (removedImages && removedImages.length > 0) {
          obj.removedImages = JSON.stringify(removedImages);
        }
        await PostService.editPost(obj);
      } else {
        await PostService.addPost(obj);
      }
      setIsLoading(false);
      // if (props.type === "modal") {
      closeAddPost();
      // }
      const message = postId
        ? "Post updated successfully"
        : "Post added successfully";
      await Swal.fire({
        title: postId ? "Edit post" : "Add post",
        text: message,
        icon: "success",
        confirmButtonText: "Ok!",
        background: "#191c24",
      });
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: postId ? "Edit post" : "Add post",
        text: message,
        icon: "error",
        confirmButtonText: "Ok!",
        background: "#191c24",
      });
    }
  };

  return (
    <div>
      {/* {console.log("value", value)} */}
      <div className="position-relative">
        {props.type !== "modal" && (
          <div class="page-header">
            <h3 class="page-title">Post</h3>
          </div>
        )}
        <div className="card">
          <div className="card-body">
            {props.type === "modal" && (
              <h4 className="card-title">
                {postId ? "Update Post" : "Add Post"}
              </h4>
            )}
            {isLoading && <CustomSpinner></CustomSpinner>}
            {/* <p className="card-description"> Basic form layout </p> */}
            <form className="forms-sample">
              {/* {avatarUrl && <div><img src={avatarUrl} alt="avatar" className="event-img"></img></div>} */}

              <div className="row">
                <Form.Group className="col-12">
                  <label>Post images</label>
                  <div class="custom-file">
                    <input
                      lang="es"
                      type="file"
                      id="customFileLang"
                      class="form-control visibility-hidden form-control-file"
                      multiple
                      onChange={handleUploadImage}
                      accept="image/*"
                    />
                    <label class="custom-file-label" for="customFileLang">
                      Upload image
                    </label>
                  </div>
                </Form.Group>
              </div>
              {(posters || avatar) && (
                <div class="form-group">
                  <div className="row">
                    <div className="col-md">
                      <div className="row no-gutters">
                        {posters &&
                          posters.map((p, index) => (
                            <div className="col-sm-4 app-img" key={index}>
                              <img
                                src={`${S3_BUCKET_URL}${p.avatarPath}`}
                                alt="event poster"
                                className="img-fluid"
                              ></img>
                              <button
                                type="button"
                                class="close-button"
                                onClick={() => removePosterImage(index)}
                              ></button>
                            </div>
                          ))}
                        {avatar &&
                          avatar.map((a, index) => (
                            <div className="col-sm-4 app-img" key={index}>
                              <img
                                src={URL.createObjectURL(a)}
                                alt="event poster"
                                className="img-fluid"
                              ></img>
                              <button
                                type="button"
                                class="close-button"
                                onClick={() => removeUploadedPosterImage(index)}
                              ></button>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <Form.Group className="col-12">
                  <label htmlFor="title">
                    Title <span className="required">*</span>
                  </label>
                  <Form.Control
                    type="text"
                    id="title"
                    value={title.value}
                    onChange={setTitleInput}
                  />
                  {title.nullValue && (
                    <div className="error">Title should not empty</div>
                  )}
                </Form.Group>
              </div>
              <Form.Group>
                <label htmlFor="description">
                  Description <span className="required">*</span>
                </label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="description"
                  value={description.value}
                  onChange={setDescriptionInput}
                />
                {description.nullValue && (
                  <div className="error">Description should not empty</div>
                )}
              </Form.Group>

              <div className="row">
                {/* <Form.Group className="col-6"> */}
                <Form.Group className="col-lg-6 col-md-6 col-sm-12">
                  <label htmlFor="category">
                    Category <span className="required">*</span>
                  </label>
                  <div>
                    <select
                      className="form-control"
                      onChange={setCategoryInput}
                      value={category.value}
                    >
                      {categories.map((c) => (
                        <option value={c.categoryId}>{c.name}</option>
                      ))}
                      {/* <option value="1">Festival</option>
													<option value="2">Sports</option> */}
                    </select>
                  </div>
                </Form.Group>
                <Form.Group className="col-lg-6 col-md-6 col-sm-12">
                  <label htmlFor="subcategory">Sub Category<span className="required">*</span></label>
                  <div>
                    <select
                      className="form-control"
                      onChange={setSubCategoryInput}
                      value={subCategory.value}
                    >
                      {subCategories.map((s) => (
                        <option value={s.categoryId}>{s.name}</option>
                      ))}
                    </select>
                  </div>
                </Form.Group>
              </div>
              <Form.Group>
                <Switch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onColor="#0090e7"
                  height={20}
                  width={40}
                  onChange={() => setActive(!active)}
                  checked={active}
                />
                <label
                  htmlFor="Active"
                  style={{ paddingLeft: "10px", lineHeight: "23px" }}
                >
                  Active
                </label>
              </Form.Group>
              <button
                type="submit"
                className="btn btn-primary mr-2"
                onClick={addPost}
              >
                Submit
              </button>
              <button className="btn btn-dark" onClick={closeAddPost}>
                Cancel
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

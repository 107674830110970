import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Login, Event, Dashboard, User } from "../pages";
import Category from "../pages/Category/Category";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import UpdateProfile from "../pages/UpdateProfile/UpdateProfile";
import EmailTemplates from "../pages/EmailTemplates/EmailTemplates";
import AddEmailTemplate from "../pages/AddEmailTemplate/AddEmailTemplate";
import AddEvent from "../pages/AddEvent/AddEvent";
import AddUser from "../pages/AddUser/AddUser";
import Payments from "../pages/Payments/Payments";
import UserLoginHistory from "../pages/UserLoginHistory/UserLoginHistory";
import Storage from "../services/Storage";
import Post from "../pages/Post/Post";
import AddPost from "../pages/AddPost/AddPost";
import Settings from "../pages/Settings/Settings";

const routeObj = [
  {
    exact: true,
    path: "/",
    component: Dashboard,
    key: "Dashboard",
  },
  {
    exact: true,
    path: "/dashboard",
    component: Dashboard,
    key: "Dashboard",
  },
  {
    exact: true,
    path: "/login",
    component: Login,
    key: "Login",
  },
  {
    exact: true,
    path: "/events",
    component: Event,
    key: "Event",
  },
  {
    exact: true,
    path: "/users",
    component: User,
    key: "User",
  },
  {
    exact: true,
    path: "/forgot-password",
    component: ForgotPassword,
    isPublic: true,
    key: "ForgotPassword",
  },
  {
    exact: true,
    path: "/profile",
    component: UpdateProfile,
    key: "UpdateProfile",
  },
  {
    exact: true,
    path: "/category",
    component: Category,
    key: "Category",
  },
  {
    exact: true,
    path: "/email-templates",
    component: EmailTemplates,
    key: "EmailTemplates",
  },
  {
    exact: true,
    path: "/add-email-template",
    component: AddEmailTemplate,
    key: "AddEmailTemplate",
  },
  {
    exact: true,
    path: "/add-event",
    component: AddEvent,
    key: "AddEmailTemplate",
  },
  {
    exact: true,
    path: "/add-user",
    component: AddUser,
    key: "AddEmailTemplate",
  },
  {
    exact: true,
    path: "/payments",
    component: Payments,
    key: "Payments",
  },
  {
    exact: true,
    path: "/login-history",
    component: UserLoginHistory,
    key: "UserLoginHistory",
  },
  {
    exact: true,
    path: "/post",
    component: Post,
    key: "Post",
  },
  {
    exact: true,
    path: "/add-post",
    component: AddPost,
    key: "AddPost",
  },
  {
    exact: true,
    path: "/settings",
    component: Settings,
    key: "Settings",
  },
];

const isAuthenticated = () => {
  const keepMeLoggenInCookie = Storage.getKeepMeLoggedInCookie();
  if (!keepMeLoggenInCookie) {
    Storage.removeTokenDetail();  
    Storage.removeUserDetail();
  }
  const token = Storage.getTokenDetail();
  if (token) {
    return true;
  }
  return false;
};
const Routes = () => {
  let routesObj = routeObj.map((route) => {
    return (
      <Route
        key={route.key}
        exact={route.exact}
        path={route.path}
        // component={route.component}
        render={(props) => {
          return isAuthenticated() || route.isPublic === true ? (
            <route.component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
              }}
            />
          );
        }}
      />
    );
  });
  return (
    <Switch>
      <Route exact path="/login" component={Login}></Route>
      {routesObj}
    </Switch>
  );
};

export default Routes;

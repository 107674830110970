// all localstorage operation will be reside here
import cookie from 'react-cookies';


function setLocalItem(key, value) {
	localStorage.setItem(key, value);
}

function getLocalItem(key) {
	return localStorage.getItem(key);
}

function removeLocalItem(key) {
	return localStorage.removeItem(key);
}

function setCookie(name, value, options) {
	cookie.save(name, value, options);
}

function getCookie(name) {
	return cookie.load(name);
}

function removeCookie(name) {
	cookie.remove(name);
}

export default class Storage {

	static setTokenDetail(token) {
		setLocalItem('token', JSON.stringify(token));
	}

	static getTokenDetail() {
		let token = getLocalItem('token');
		return JSON.parse(token);
	}


	static removeTokenDetail() {
		removeLocalItem('token');
	}


	static setUserDetail(userDetail) {
		setLocalItem('user-detail', JSON.stringify(userDetail));
	}

	static getUserDetail() {
		let userDetail = getLocalItem('user-detail');
		return JSON.parse(userDetail);
	}

	static removeUserDetail() {
		removeLocalItem('user-detail');
	}

	static setKeepMeLoggedInCookie(value) {
		if (value) {
			setCookie('KeepMeLoggedIn', true, { maxAge: 21321231312 });
		} else {
			setCookie('KeepMeLoggedIn', false, null);
		}
	}

	static getKeepMeLoggedInCookie() {
		return getCookie('KeepMeLoggedIn');
	}

	static removeKeepMeLoggedInCookie() {
		removeCookie('KeepMeLoggedIn');
	}
}
import { request } from './Request';
import { routes } from '../constants/constant.route';
const { ADD_UPDATE_EMAIL_TEMPLATES, LIST_EMAIL_TEMPLATES } = routes.EMAIL_TEMPLATE;
const API_URL = process.env.REACT_APP_API_URL;


export default class EmailTemplateService {
	static listEmailTemplates(body) {
		// let q = ``;
		// if (page) {
		// 	q += `page=${page}&`
		// }
		// if (limit) {
		// 	q += `limit=${limit}&`
		// }
		// if (search) {
		// 	q += `search=${search}&`
		// }
		// if (name) {
		// 	q += `name=${name}&`
		// }
		return request('POST', `${API_URL}${LIST_EMAIL_TEMPLATES}`, null, body, null);
	}

	static addEmailTemplate(body) {
		return request('POST', `${API_URL}${ADD_UPDATE_EMAIL_TEMPLATES}`, null, body, null);
	}
	static updateEmailTemplate(body) {
		// const headers = {
		// 	'content-type': 'multipart/form-data'
		// };
		return request('PUT', `${API_URL}${ADD_UPDATE_EMAIL_TEMPLATES}`, null, body, null);
	}
}
import { request } from './Request';
import { routes } from '../constants/constant.route';
import Storage from "./Storage";

const { FORGOT_PASSWORD, SIGNIN } = routes.AUTH;
const API_URL = process.env.REACT_APP_API_URL;

export default class AuthService {
	static signin(signinData) {
		const headers = {
			'content-type': 'application/x-www-form-urlencoded'
		};
		signinData = { ...signinData, grant_type: 'password' };
		return request('POST', `${API_URL}${SIGNIN}`, null, signinData, headers);
	}

	static forgotPassword(body) {
		return request('POST', `${API_URL}${FORGOT_PASSWORD}`, null, body, null);
	}

	static logout() {
		Storage.removeKeepMeLoggedInCookie();
		Storage.removeTokenDetail();
		Storage.removeUserDetail();
		// this.closeLogoutModal();
		// this.setState({ redirectToLoginPage: true });
	}
}

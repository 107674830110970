import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import Switch from "react-switch";
import ReactQuill from 'react-quill';
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import { notNullValidator } from "../../services/Validation";
// import { S3_BUCKET_URL } from '../../constants/constant.app';
import EmailTemplateService from "../../services/EmailTemplateService";
import { RECORDS_PER_PAGE } from '../../constants/constant.app';

import 'react-quill/dist/quill.snow.css';
import "react-datepicker/dist/react-datepicker.css";

const controlObj = {
  value: "",
  valid: null,
  touched: false,
  nullValue: null,
};

const templates = [
  'user_registration_confirmation_via_email',
  'user_registration_confirmation_via_otp',
  'user_registration_completed_successfully',
  'forgot_password',
  'notify_admin_for_new_event_added',
  'notify_event_members_for_new_event',
  'notify_admin_to_update_event_details',
  'notify_event_members_to_event_details_updated',
  'user_creation_by_admin'
]
// const s3BucketUrl = `http://s3.me-south-1.amazonaws.com/stayactive-development/`;

export default function AddEditEmailTemplate(props) {
  // const [value, onChange] = useState(new Date());
  // const [hours, setHours] = useState(0);
  // const [minutes, setMinutes] = useState(0);
  let [isFormValid, setIsFormValid] = useState(false);
  let [name, setName] = useState({ ...controlObj });
  let [subject, setSubject] = useState({ ...controlObj });
  let [content, setContent] = useState({ ...controlObj });
  let [emailTemplateId, setEmailTemplateId] = useState(null);
  let [isActive, setIsActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const emailTemplateObj = props?.emailTemplate || props?.location?.state;
    if (emailTemplateObj) {
      const {
        // avatarPath,

        isActive,
        // avatarName,
        // avatarWidth,
        // avatarHeight,
        emailTemplateId,
        name: nameFromDb,
        subject: subjectFromDb,
        content: contentFromDb,
      } = emailTemplateObj;
      setName(() => setInitialInputValue(nameFromDb, name));
      setIsActive(isActive);
      setSubject(() => setInitialInputValue(subjectFromDb, subject))
      setContent(() => setInitialInputValue(contentFromDb, content))
      setEmailTemplateId(emailTemplateId)
      // setProfileImage(avatarPath);
    }
  }, [props.emailTemplate]);
  function setInitialInputValue(value, input) {
    if (!value) {
      input.value = "";
    } else {
      input.value = value;
    }
    return { ...input };
  }
  const setInputValue = (e, input) => {
    const value = e.target.value;
    input.touched = true;
    input.value = value;
    console.log("input", input);
    return { ...input };
  };

  const setNameInput = (e) => {
    setName(() => setInputValue(e, name));
    getEmailTemplateDetail(e.target.value);
  };

  const setSubjectInput = (e) => {
    setSubject(() => setInputValue(e, subject));
  };

  const setContentInput = (e) => {
    setContent(() => setInputValue(e, content));
  };

  const handleValidation = () => {
    // let isFormValid = false;
    name = notNullValidator(name);
    name.valid = !name.nullValue;

    subject = notNullValidator(subject);
    subject.valid = !subject.nullValue;

    // content = notNullValidator(content);
    if (!content || content.value === '<p><br></p>') {
      content.nullValue = true;
    } else {
      content.nullValue = false;
    }
    content.valid = !content.nullValue;

    if (
      name.valid === true &&
      subject.valid === true &&
      content.valid === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    setName({ ...name });
    setIsFormValid(isFormValid);
    return isFormValid;
  };

  const closeModal = () => {
    setIsFormValid(false);
    setName({ ...controlObj });
    setSubject({ ...controlObj });
    setContent({ ...controlObj });
    if (props.type === "modal") {
      props.closeModal();
    } else if (props.type === "component") {
      // do nothing
    } else {
      props.history.push({
        pathname: '/email-templates',
      });
    }
    // props.closeModal();
  };

  const setContentEditor = (value) => {
    console.log("value", value);
    setContent({ ...content, value })
  }
  const addEmailTemplate = async (emailTemplate) => {
    try {
      emailTemplate.preventDefault();
      const isEmailTemplateFormValid = handleValidation();
      console.log("isEmailTemplateFormValid", isEmailTemplateFormValid);
      if (isEmailTemplateFormValid === false) {
        return;
      }

      const obj = {
        name: name.value,
        subject: subject.value,
        content: content.value,
        isActive
      };

      setIsLoading(true);
      if (emailTemplateId) {
        obj.emailTemplateId = emailTemplateId;
        await EmailTemplateService.updateEmailTemplate(obj);
      } else {
        await EmailTemplateService.addEmailTemplate(obj);
      }
      setIsLoading(false);
      const message = emailTemplateId ? 'Email Template updated successfully' : 'Email Template added successfully';
      await Swal.fire({
        title: emailTemplateId ? "Edit Email Template" : "Add Email Template",
        text: message,
        icon: "success",
        confirmButtonText: "Ok!",
        background: "#191c24",
      });
      // if (props.type === "modal") {
      closeModal();
      // }
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: emailTemplateId ? "Edit Email Template" : "Add Email Template",
        text: message,
        icon: "error",
        confirmButtonText: "Ok!",
        background: "#191c24",
      });
    }
  };

  const getEmailTemplateDetail = async (templateName) => {
    getEmailTemplateList(templateName)
  }

  const getEmailTemplateList = async (nameToFind) => {
    try {
      setIsLoading(true);
      const getEmailTemplateObj = {
        page: 1,
        limit: RECORDS_PER_PAGE,
        name: nameToFind
      }
      const emailTemplateFromService = await EmailTemplateService.listEmailTemplates(getEmailTemplateObj);
      const {
        data: { data, totalPages },
      } = emailTemplateFromService;
      console.log("data[0]", data[0]);
      if (data[0]) {
        const emailTemplateDetail = data[0];
        setName(() => setInitialInputValue(emailTemplateDetail.name, name));
        setSubject(() => setInitialInputValue(emailTemplateDetail.subject, subject));
        setContent(() => setInitialInputValue(emailTemplateDetail.content, content));
        setEmailTemplateId(emailTemplateDetail.emailTemplateId);
      } else {
        setSubject({ ...controlObj });
        setContent({ ...controlObj });
        setEmailTemplateId(null);
      }
      // setEmailTemplates([...data]);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: "List email templates",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    }
  };

  return (
    <div className="position-relative">
      {isLoading && <CustomSpinner></CustomSpinner>}
      {/* <Loader
        className="loader"
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        visible //3 secs
      /> */}
      {props.type !== "modal" && <div class="page-header">
        <h3 class="page-title">Email Template</h3>
      </div>}

      <div className="card">
        <div className="card-body">
          {props.type === "modal" && <h4 className="card-title">
            {emailTemplateId ? "Update Email Template" : "Add Email Template"}
          </h4>}
          {/* <p className="card-description"> Basic form layout </p> */}
          <form className="forms-sample">
            <Form.Group>
              <label htmlFor="name">Name <span className="required">*</span></label>
              <div>
                <select
                  className="form-control"
                  onChange={setNameInput}
                  value={name.value}
                >
                  <option value={""}>Select</option>
                  {templates.map((t) => (
                    <option value={t}>{t}</option>
                  ))}
                  {/* <option value="1">Festival</option>
													<option value="2">Sports</option> */}
                </select>
              </div>
            </Form.Group>
            <Form.Group>
              <label htmlFor="subject">Subject <span className="required">*</span></label>
              <Form.Control
                type="text"
                id="subject"
                value={subject.value}
                onChange={setSubjectInput}
              />
              {subject.nullValue && (
                <div className="error">Subject should not empty</div>
              )}
            </Form.Group>
            <Form.Group>
              <label htmlFor="Content">Content <span className="required">*</span></label>
              <ReactQuill theme="snow" value={content.value} onChange={(val) => setContentEditor(val)} />
              {content.nullValue && (
                <div className="error">Content should not empty</div>
              )}
            </Form.Group>

            <div className="row">
              <Form.Group className="col-6">
                <Switch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onColor="#0090e7"
                  height={20}
                  width={40}
                  onChange={() => setIsActive(!isActive)}
                  checked={isActive}
                />
                <label
                  htmlFor="Active"
                  style={{ paddingLeft: "10px", lineHeight: "21px" }}
                >
                  Active
                      </label>
                {/* <Form.Control type="text" id="Trending" placeholder="Trending" /> */}
              </Form.Group>
            </div>
            {/* <div className="form-check">
										<label className="form-check-label text-muted">
											<input type="checkbox" className="form-check-input" />
											<i className="input-helper"></i>
                      Remember me
                    </label>
									</div> */}
            <button
              type="submit"
              className="btn btn-primary mr-2"
              onClick={addEmailTemplate}
            >
              Submit
            </button>

            <button className="btn btn-dark" onClick={closeModal}>
              Cancel
              </button>

          </form>
        </div>
      </div>
    </div>
  );
}

import { request } from './Request';
import { routes } from '../constants/constant.route';
const { ADD_UPDATE_POST, LIST_POST, DELETE_POST } = routes.POST;
const API_URL = process.env.REACT_APP_API_URL;


export default class PostService {
	static addPost(postData) {
		const headers = {
			'content-type': 'multipart/form-data'
		};
		return request('POST', `${API_URL}${ADD_UPDATE_POST}`, null, postData, headers);
	}

	static editPost(postData) {
		const headers = {
			'content-type': 'multipart/form-data'
		};
		return request('PUT', `${API_URL}${ADD_UPDATE_POST}`, null, postData, headers);
	}

	static deletePost(postData) {
		return request('POST', `${API_URL}${DELETE_POST}`, null, postData, null);
	}

	static listPost(postData) {
		return request('POST', `${API_URL}${LIST_POST}`, null, postData, null);
	}
}
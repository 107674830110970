import { request } from './Request';
import { routes } from '../constants/constant.route';

const { GET_TIMEZONES } = routes.TIMEZONE;
const API_URL = process.env.REACT_APP_API_URL;

export default class TimezoneService {
    static getTimezones() {
        return request('GET', `${API_URL}${GET_TIMEZONES}`, null, null, null);
    }
}

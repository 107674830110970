import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import { notNullValidator } from '../../services/Validation';
import AuthService from '../../services/AuthService';
import StorageService from '../../services/Storage';
import logo from '../../assets/images/logo.svg';

const { Group, Control } = Form;
export function ForgotPassword(props) {
    let [isFormValid, setIsFormValid] = useState(false);
    let [errorMessage, setErrorMessage] = useState(null);
    let [redirectToLogin, setRedirectToLogin] = useState(false);
    let [email, setEmail] = useState({
        value: "",
        valid: null,
        touched: false,
        nullValue: null,
    });

    const setEmailData = (control) => {
        const obj = {
            value: control.target.value,
            valid: null,
            touched: true,
            nullValue: null,
        };
        setErrorMessage(null);
        setEmail(obj);
    };

    useEffect(() => {
        if (isAuthenticated()) {
            props.history.push('/dashboard')
        }
    })

    const isAuthenticated = () => {
        const token = StorageService.getTokenDetail();
        if (token) {
            return true;
        }
        return false;
    };

    const handleValidation = () => {
        // let isFormValid = false;
        email = notNullValidator(email);
        console.log("email notNullValidator", email);
        email.valid = !email.nullValue;

        if (email.valid === true) {
            isFormValid = true;
        } else {
            isFormValid = false;
        }
        setEmail({ ...email });
        setIsFormValid(isFormValid);
        return isFormValid;
    };

    const forgotPassword = async (event) => {
        try {
            event.preventDefault();
            const isSigninFormValid = handleValidation();
            if (isSigninFormValid === false) {
                return;
            }
            const obj = {
                email: email.value,
            };
            const response = await AuthService.forgotPassword(obj);
            setRedirectToLogin(true);
            return response;
        } catch (e) {
            const message = e?.response?.data?.message || "Something went wrong";
            setErrorMessage(message);
        }
    };

    return (
        <div>
            {redirectToLogin && <Redirect to="/login"></Redirect>}
            <div className="d-flex align-items-center auth px-0">
                <div className="row w-100 mx-0">
                    <div className="col-lg-4 mx-auto">
                        <div className="card text-left py-5 px-4 px-sm-5">
                            <div className="brand-logo">
                                <img src={logo} alt="logo" />
                            </div>
                            <h4>Forgot password</h4>
                            {/* <h6 className="font-weight-light">Sign in to continue.</h6> */}
                            <Form className="pt-3">
                                <Group className="search-field">
                                    <Control
                                        type="email"
                                        placeholder="Email"
                                        size="lg"
                                        className="h-auto"
                                        onChange={setEmailData}
                                        value={email.value}
                                    />
                                    {email.nullValue && (
                                        <div class="error">Email should not be empty</div>
                                    )}
                                </Group>
                                <div className="mt-3">
                                    <button
                                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                                        onClick={forgotPassword}
                                    >
                                        Submit
                  </button>
                                    <div className="error">{errorMessage}</div>
                                </div>
                                <div className="my-2 d-flex justify-content-between align-items-center">
                                    {/* <div className="form-check">
                    <label className="form-check-label text-muted">
                      <input type="checkbox" className="form-check-input" />
                      <i className="input-helper"></i>
                        Keep me signed in
                      </label>
                  </div> */}
                                    <div onClick={() => setRedirectToLogin(true)} className="auth-link text-muted">Login</div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ForgotPassword;

import React, { useState, useEffect, useCallback } from "react";
import Switch from "react-switch";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";
// import DatePicker from "react-datepicker";
import Pagination from "../../component/Pagination/Pagination";
import AddPost from "../../Modal/AddPost/AddPost";
import PostService from "../../services/PostService";
import UtilsService from "../../services/UtilsService";
import CustomSpinner from "../../component/CustomSpinner/CustomSpinner";
import { RECORDS_PER_PAGE } from "../../constants/constant.app";
import { debounce } from "lodash";
// import ReactPaginate from 'react-paginate';
export default function Post(props) {
  const [page, setPage] = useState(1);
  const [posts, setPosts] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState("");
  const [active, setActive] = useState("");
  const [openAddPostPage, setOpenAddPostPage] = useState(false);
  const [postToEdit, setPostToEdit] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchInitialState, setIsSearchInitialState] = useState(true);
  const [isActiveInitialState, setIsActiveInitialState] = useState(true);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [status, setStatus] = useState("");
  const pageRange = 3;
  const marginPagesDisplayed = 2;
  let isActiveState = props?.location?.state?.isActive;
  let statusState = props?.location?.state?.status;
  if ((isActiveState === true || isActiveState === false)) {
    isActiveState = isActiveState.toString()
  } else {
    isActiveState = null;
  }
  // const [showAlert, setShowAlert] = useState(false);
  // const [deleteIndex, setDeleteIndex] = useState(null);

  useEffect(() => {
    // debounce(searchUserList, 1000);
    if (isActiveState) {
      setActive(isActiveState);
    }
    if (['completed', 'in-progress', 'pending'].includes(statusState)) {
      setStatus(statusState);
    }
    // if (isUnverifiedState === true || isUnverifiedState === false) {
    //   setStatus(isUnverifiedState ? 'unverified' : null);
    // }
    if (!(isActiveState === "true" || isActiveState === "false" || ['completed', 'in-progress', 'pending'].includes(statusState))) {
      getPosts(1)
    }
  }, []);

  useEffect(() => {
    if (isFirstTime && !isActiveInitialState) {
      getPosts(1, null, search)
      setIsFirstTime(false)
    }
    setIsActiveInitialState(false)
  }, [active]);

  useEffect(() => {
    if (!isSearchInitialState) {
      debouncedChangeHandler(search);
    }
    setIsSearchInitialState(false)
  }, [search]);

  const searchPostList = (search) => {
    setPage(1);
    const filterObj = {
      active,
      status
    };
    getPosts(1, filterObj, search)
  }

  const debouncedChangeHandler = useCallback(
    debounce(searchPostList, 1000),
    []
  )



  const handleIsActiveChange = async (index) => {
    const event = posts[index];
    event.isActive = !event.isActive;
    try {
      await editPost(event);
      setPosts([...posts]);
    } catch (e) {
      console.log("e", e);
    }
  };

  const openDeletePostPopup = async (index) => {
    // setShowAlert(true);
    // setDeleteIndex(index);
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete post?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      background: "#191c24",
    });
    if (result.isConfirmed) {
      deletePost(index);
    } else if (result.dismiss === Swal.DismissReason.cancel) {
    }
  };

  const getPosts = async (page, filterObj, search) => {
    const postObj = {
      page,
      pageSize: RECORDS_PER_PAGE,
      search,
    };
    const activeToConsider =
      filterObj && filterObj.active ? filterObj.active : active;
    if (activeToConsider === "true") {
      postObj.active = true;
    } else if (activeToConsider === "false") {
      postObj.active = false;
    }

    const statusToConsider =
      filterObj && filterObj.status ? filterObj.status : status;
    if (statusToConsider) {
      postObj.status = statusToConsider;
    }

    setIsLoading(true);
    try {
      const posts = await PostService.listPost(postObj);
      const { data, totalPages } = posts;
      setPosts(data);
      setPageCount(totalPages);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: "List posts",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    }
  };

  const editPost = async (post) => {
    try {
      const obj = {
        isActive: post.isActive,
        id: post.postId,
      };
      setIsLoading(true);
      await PostService.editPost(obj);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: "Edit post",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    }
  };

  const deletePost = async (index) => {
    const post = posts[index];
    const deletePostObj = {
      postId: post.postId,
    };
    try {
      setIsLoading(true);
      await PostService.deletePost(deletePostObj);
      await getPosts(1, null, search);
      setPage(1);
      setIsLoading(false);
      const message = "Post deleted successfully";
      await Swal.fire({
        title: "Delete post",
        text: message,
        icon: "success",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: "Delete post",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    }
    // call api to delete event
    //
    // setDeleteIndex(null);
  };

  const onPageChange = (page) => {
    getPosts(page, null, search);
    setPage(page);
  };

  const openEditPostModal = (post) => {
    console.log("props", props);
    props.history.push({
      pathname: '/add-post',
      state: post
    });
    // setEventToEdit({ ...event });
    // setOpenAddEventPage(!openAddEventPage);
  };

  const closeAddPostModal = () => {
    setOpenAddPostPage(!openAddPostPage);
    setPostToEdit(null);
    getPosts(1, null, search);
    setPage(1);
  };

  const resetFilter = async () => {
    setActive("");
    const filterObj = {
      active: "all",
    };
    getPosts(1, filterObj, search);
  };
  return (
    <div>
      <div class="page-header">
        <h3 class="page-title"> Posts </h3>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">
              <button
                class="btn btn-primary"
                onClick={() => openEditPostModal(null)}
              // onClick={() => setOpenAddEventPage(!openAddEventPage)}
              >
                Add Post
              </button>
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        {openAddPostPage && <AddPost
          post={postToEdit}
          show={openAddPostPage}
          closeModal={closeAddPostModal}
        ></AddPost>}
        <div class="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              {isLoading && <CustomSpinner></CustomSpinner>}
              {/* <p className="card-description"> Add className <code>.table</code> */}
              {/* </p> */}
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <Form.Group>
                    <label htmlFor="exampleInputPassword1">Search</label>
                    <Form.Control
                      type="text"
                      id="search"
                      placeholder="Search.."
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </Form.Group>
                </div>

                {/* </div>
              <div className="row"> */}
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <Form.Group>
                    <label htmlFor="type">Active</label>
                    <div>
                      <select
                        className="form-control"
                        onChange={(e) => setActive(e.target.value)}
                        value={active}
                      >
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12" style={{ margin: "auto 0px" }}>
                  <button class="btn btn-primary" onClick={() => getPosts(1, null, search)}>
                    Apply
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-warning btn-icon-text"
                    onClick={resetFilter}
                  >
                    Reset
                  </button>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Active</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {posts.map((p, index) => (
                      <tr>
                        <td>{p.title}</td>
                        <td>
                          <Switch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor="#0090e7"
                            height={20}
                            width={40}
                            onChange={() => handleIsActiveChange(index)}
                            checked={p.isActive}
                          />
                        </td>
                        <td>

                          <i
                            className="mdi mdi-table-edit"
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "#0090e7",
                            }}
                            title="Edit event"
                            onClick={() =>
                              openEditPostModal(p)
                            }
                          ></i>
                                &nbsp;&nbsp;
                                <i
                            className="mdi mdi-delete"
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "#0090e7",
                            }}
                            title="Delete event"
                            onClick={() => openDeletePostPopup(index)}
                          ></i>
                        </td>
                      </tr>
                    ))}

                    {posts && posts.length === 0 && (
                      <tr>
                        <td colSpan="9" style={{ textAlign: "center" }}>
                          No posts available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {pageCount > 1 && (
                  <Pagination
                    totalRecords={pageCount * RECORDS_PER_PAGE}
                    itemsCountPerPage={RECORDS_PER_PAGE}
                    page={page}
                    pageCount={pageCount}
                    pageRangeDisplayed={pageRange}
                    marginPagesDisplayed={marginPagesDisplayed}
                    onPageChange={onPageChange}
                  ></Pagination>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { request } from './Request';
import { routes } from '../constants/constant.route';
const { ADD_UPDATE_EVENT, LIST_EVENT, DELETE_EVENT, LIST_EVENT_MEMBERS, GET_EVENT_COUNTS } = routes.EVENT;
const API_URL = process.env.REACT_APP_API_URL;


export default class EventService {
	static addEvent(eventData) {
		const headers = {
			'content-type': 'multipart/form-data'
		};
		return request('POST', `${API_URL}${ADD_UPDATE_EVENT}`, null, eventData, headers);
	}

	static editEvent(eventData) {
		const headers = {
			'content-type': 'multipart/form-data'
		};
		return request('PUT', `${API_URL}${ADD_UPDATE_EVENT}`, null, eventData, headers);
	}

	static deleteEvent(eventData) {
		return request('POST', `${API_URL}${DELETE_EVENT}`, null, eventData, null);
	}

	static listEvent(eventData) {
		return request('POST', `${API_URL}${LIST_EVENT}`, null, eventData, null);
	}

	static listEventMembers(eventId) {
		return request('GET', `${API_URL}${LIST_EVENT_MEMBERS}?eventId=${eventId}`, null, null, null);
	}

	static getEventCount() {
		return request('GET', `${API_URL}${GET_EVENT_COUNTS}`, null, null, null);
	}
}
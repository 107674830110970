import React, { useEffect, useState } from "react";
import CustomSpinner from "../CustomSpinner/CustomSpinner";

// const s3BucketUrl = `http://s3.me-south-1.amazonaws.com/stayactive-development/`;

export default function PaymentDetailComponent(props) {
  // const [value, onChange] = useState(new Date());
  // const [hours, setHours] = useState(0);
  // const [minutes, setMinutes] = useState(0);
  let [paymentDetail, setPaymentDetail] = useState({
    name: 'Arpan Shah',
    title: 'Diwali',
    transactionId: 540,
    transactionDate: '29-11-1995',
    transactionStatus: 'success',
    amount: 560
  })
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.paymentDetail) {
      setPaymentDetail(props.paymentDetail)
    }
  }, []);



  const closeModal = () => {
    props.closeModal();
  };


  return (
    <div className="grid-margin stretch-card">
      {isLoading && <CustomSpinner></CustomSpinner>}
      {/* <Loader
        className="loader"
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        visible //3 secs
      /> */}
      <div className="card">
        <div class="page-header">
          <h3 class="page-title">Payment Detail</h3>
          <span>
            <i
              className="mdi mdi-close"
              style={{
                cursor: "pointer",
                fontSize: "20px",
                color: "#0090e7",
              }}
              title="View Details"
              onClick={() => closeModal()}
            ></i>
          </span>
        </div>
        <div>
          {/* <h4 className="card-title">
            Payment Detail
          </h4>
          <hr></hr> */}
          {/* <p className="card-description"> Basic form layout </p> */}
          <div className="row">
            <div className="col-6"><b>Name</b></div>
            <div className="col-6">{paymentDetail.name}</div>
          </div>
          <div className="row">
            <div className="col-6"><b>Event</b></div>
            <div className="col-6">{paymentDetail.title}</div>
          </div>
          <div className="row">
            <div className="col-6"><b>Transaction Id</b></div>
            <div className="col-6">{paymentDetail.transactionId}</div>
          </div>
          <div className="row">
            <div className="col-6"><b>Transaction Date</b></div>
            <div className="col-6">{paymentDetail.transactionDate}</div>
          </div>
          <div className="row">
            <div className="col-6"><b>Transaction Status</b></div>
            <div className="col-6">{paymentDetail.transactionStatus}</div>
          </div>
          <div className="row">
            <div className="col-6"><b>Transaction Amount</b></div>
            <div className="col-6">{paymentDetail.amount}</div>
          </div>
        </div>
      </div>
    </div >
  );
}

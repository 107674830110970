import React from 'react';
import AddEditCategory from '../../component/AddEditCategory/AddEditCategory';

export default function AddCategory(props) {
  const closeModal = () => {
    props.closeModal();
  }

  return <div class="modal" tabindex="-1" role="dialog" style={{ display: props.show === true ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.6)' }}>
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <AddEditCategory
            category={props.category}
            subCategory={props.subCategory}
            module={props.module}
            closeModal={closeModal}
            type='modal'
          ></AddEditCategory>
        </div>
      </div>
    </div>
  </div>
}
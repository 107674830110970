import Pagination from "react-js-pagination";

export default function PaginationComponent(props) {
	const {
		page, itemsCountPerPage, totalRecords
	} = props;
	const onPageChange = (page) => {
		// setPage(page.selected);
		props.onPageChange(page);
	}
	return <div>
		<Pagination
			activePage={page}
			itemsCountPerPage={itemsCountPerPage}
			totalItemsCount={totalRecords}
			pageRangeDisplayed={5}
			onChange={onPageChange}
			itemClass="c-page"
			linkClass="page-link-pg"
			itemClassNext="c-prev"
			activeClass="page-active"
			activeLinkClass="page-link-active"
			style={{ marginTop: '16px' }}
		/>
	</div>
}
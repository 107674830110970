import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import Switch from "react-switch";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import { notNullValidator } from "../../services/Validation";
import { S3_BUCKET_URL } from "../../constants/constant.app";

import "react-datepicker/dist/react-datepicker.css";

import CategoryService from "../../services/CategoryService";

const controlObj = {
  value: "",
  valid: null,
  touched: false,
  nullValue: null,
};
// const s3BucketUrl = `http://s3.me-south-1.amazonaws.com/stayactive-development/`;

export default function AddEditCategory(props) {
  // const [value, onChange] = useState(new Date());
  // const [hours, setHours] = useState(0);
  // const [minutes, setMinutes] = useState(0);
  let [isFormValid, setIsFormValid] = useState(false);
  let [name, setName] = useState({ ...controlObj });
  let [avatar, setAvatar] = useState(null);
  let [profileImage, setProfileImage] = useState(null);
  let [isActive, setIsActive] = useState(true);
  let [category, setCategory] = useState({ ...controlObj });
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    console.log("props.category", props.category);
    if (props.module === "subcategory") {
      getCategoryList(1);
    }

    if (props.category) {
      const categoryToConsider = props.category;
      const {
        avatarPath,
        isActive,
        parentCategoryId,
        // avatarName,
        // avatarWidth,
        // avatarHeight,
        name: nameFromDb,
      } = categoryToConsider;
      setName(() => setInitialInputValue(nameFromDb, name));
      setIsActive(isActive);
      setProfileImage(avatarPath);
      if (props.module === "subcategory") {
        setCategory(() => setInitialInputValue(parentCategoryId, category));
      }
    }
  }, [props.category, props.module]);
  function setInitialInputValue(value, input) {
    if (!value) {
      input.value = "";
    } else {
      input.value = value;
    }
    return { ...input };
  }
  const setInputValue = (e, input) => {
    const value = e.target.value;
    input.touched = true;
    input.value = value;
    console.log("input", input);
    return { ...input };
  };

  const setNameInput = (e) => {
    setName(() => setInputValue(e, name));
  };

  const setCategoryInput = (e) => {
    setCategory(() => setInputValue(e, category));
  };

  const handleValidation = () => {
    // let isFormValid = false;
    name = notNullValidator(name);
    name.valid = !name.nullValue;

    if (props.module === "subcategory") {
      category = notNullValidator(category);
      category.valid = !category.nullValue;
    }

    if (props.module === "subcategory") {
      if (category.valid === false || category.value === '0') {
        isFormValid = false;
      } else {
        isFormValid = true;
      }
    } else {
      isFormValid = true;
    }
    console.log("name", name);
    console.log("category", category);
    if (isFormValid && name.valid === true) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }

    setName({ ...name });
    setIsFormValid(isFormValid);
    return isFormValid;
  };

  const closeModal = () => {
    setIsFormValid(false);
    setName({ ...controlObj });
    setProfileImage(null);
    setAvatar(null);
    props.closeModal();
  };

  const removeUploadedImage = async () => {
    setAvatar(null);
  };

  const handleUploadImage = async (event) => {
    const file = event.target.files[0];
    console.log("file", file);
    setProfileImage(null);
    setAvatar(file);
  };

  const removeProfileImage = async (index) => {
    setProfileImage(null);
  };

  const addCategory = async (event) => {
    try {
      event.preventDefault();
      const isUserFormValid = handleValidation();
      console.log("isUserFormValid", isUserFormValid);
      if (isUserFormValid === false) {
        return;
      }
      const obj = {
        name: name.value,
        isActive,
      };
      console.log("avatar inside add category", avatar);
      if (avatar) {
        obj.avatar = [avatar];
      }
      if (!avatar && !profileImage) {
        obj.removeProfileImage = true;
      }

      if (props.module === 'subcategory') {
        obj.parentCategoryId = category.value;
      }
      setIsLoading(true);
      if (props.category && props.category.categoryId) {
        obj.categoryId = props.category.categoryId;
        await CategoryService.updateCategory(obj);
      } else {
        await CategoryService.addCategory(obj);
      }
      setIsLoading(false);
      const message = props?.category?.categoryId
        ? `${props.module === "subcategory" ? 'Subcategory' : 'Category'}  updated successfully`
        : `${props.module === "subcategory" ? 'Subcategory' : 'Category'} added successfully`;
      await Swal.fire({
        title: props?.category?.categoryId ? `Edit ${props.module === "subcategory" ? 'Subcategory' : 'Category'}` : `Add ${props.module === "subcategory" ? 'Subcategory' : 'Category'}`,
        text: message,
        icon: "success",
        confirmButtonText: "Ok!",
        background: "#191c24",
      });
      if (props.type === "modal") {
        closeModal();
      }
    } catch (e) {
      console.log("e", e);
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: props?.category?.categoryId ? `Edit ${props.module === "subcategory" ? 'Subcategory' : 'Category'}` : `Add ${props.module === "subcategory" ? 'Subcategory' : 'Category'}`,
        text: message,
        icon: "error",
        confirmButtonText: "Ok!",
        background: "#191c24",
      });
    }
  };

  const getCategoryList = async (page, active = null, search) => {
    try {
      const getCategoryObj = {
        page,
        limit: 100000,
      }

      if (search) {
        getCategoryObj.search = search;
      }
      setIsLoading(true);
      const categoryFromService = await CategoryService.listCategory(getCategoryObj);
      const {
        data: { data },
      } = categoryFromService;
      // setPageCount(totalPages);
      setCategories([...data]);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: "List categories",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    }
  };

  return (
    <div className="grid-margin stretch-card">
      {isLoading && <CustomSpinner></CustomSpinner>}
      {/* <Loader
        className="loader"
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        visible //3 secs
      /> */}
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">
            {props.category && props.category.categoryId
              ? `Update ${props.module === "subcategory" ? 'subcategory' : 'category'}`
              : `Add ${props.module === "subcategory" ? 'subcategory' : 'category'}`}
          </h4>
          {/* <p className="card-description"> Basic form layout </p> */}
          <form className="forms-sample">
            <Form.Group>
              <label>{`${props.module === "subcategory" ? 'Subcategory' : 'Category'}`} Image</label>
              <div class="custom-file">
                <input
                  lang="es"
                  type="file"
                  id="customFileLang"
                  class="form-control visibility-hidden form-control-file"
                  onChange={handleUploadImage}
                  accept="image/*"
                />
                <label class="custom-file-label" for="customFileLang">
                  Upload image
                </label>
              </div>
            </Form.Group>
            {(avatar || profileImage) && (
              <div class="form-group">
                <div className="row">
                  <div className="col-md">
                    <div className="row no-gutters">
                      {avatar && (

                        <div className="col-sm-4 app-img">
                          {console.log("avatar image inside", avatar)}
                          <img
                            src={URL.createObjectURL(avatar)}
                            alt="category"
                            className="img-fluid"
                          ></img>
                          <button
                            type="button"
                            class="close-button"
                            onClick={() => removeUploadedImage()}
                          ></button>
                        </div>
                      )}
                      {profileImage && (
                        <div className="col-sm-4 app-img">
                          <img
                            src={`${S3_BUCKET_URL}${profileImage}`}
                            alt="category"
                            className="img-fluid"
                          ></img>
                          <button
                            type="button"
                            class="close-button"
                            onClick={() => removeProfileImage()}
                          ></button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Form.Group>
              <label htmlFor="name">
                Name <span className="required">*</span>
              </label>
              <Form.Control
                type="text"
                id="name"
                value={name.value}
                onChange={setNameInput}
              />
              {name.nullValue && (
                <div className="error">Name should not empty</div>
              )}
            </Form.Group>

            {props.module === "subcategory" && <Form.Group>
              <label htmlFor="category">
                Category <span className="required">*</span>
              </label>
              <div>
                <select
                  className="form-control"
                  onChange={setCategoryInput}
                  value={category.value}
                >
                  {categories.map((c) => (
                    <option value={c.categoryId}>{c.name}</option>
                  ))}

                </select>
              </div>
            </Form.Group>}

            <div className="row">
              <Form.Group className="col-6">
                <Switch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onColor="#0090e7"
                  height={20}
                  width={40}
                  onChange={() => setIsActive(!isActive)}
                  checked={isActive}
                />
                <label
                  htmlFor="Active"
                  style={{ paddingLeft: "10px", lineHeight: "21px" }}
                >
                  Active
                </label>
                {/* <Form.Control type="text" id="Trending" placeholder="Trending" /> */}
              </Form.Group>
            </div>
            {/* <div className="form-check">
										<label className="form-check-label text-muted">
											<input type="checkbox" className="form-check-input" />
											<i className="input-helper"></i>
                      Remember me
                    </label>
									</div> */}
            <button
              type="submit"
              className="btn btn-primary mr-2"
              onClick={addCategory}
            >
              Submit
            </button>
            {props.type === "modal" && (
              <button className="btn btn-dark" onClick={closeModal}>
                Cancel
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

import { combineReducers } from "redux";
import sampleReducer from "./sampleReducer";
import UserReducer from "./UserReducer";

export default combineReducers({
    sample: sampleReducer,
    user: UserReducer
})



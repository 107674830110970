import { request } from './Request';
import { routes } from '../constants/constant.route';
const { LIST_SETTINGS, UPDATE_SETTINGS } = routes.SETTINGS;
const API_URL = process.env.REACT_APP_API_URL;


export default class SettingService {
  static editSetting(settingData) {
    // const headers = {
    //   'content-type': 'multipart/form-data'
    // };
    return request('PUT', `${API_URL}${UPDATE_SETTINGS}`, null, settingData, null);
  }

  static listSettings() {
    return request('GET', `${API_URL}${LIST_SETTINGS}`, null, null, null);
  }
}
import React, { useEffect, useState, useRef } from "react";
import Switch from "react-switch";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import {
  notNullValidator,
  validatePositiveNumber,
} from "../../services/Validation";
import EventService from "../../services/EventService";
import CategoryService from "../../services/CategoryService";
import UtilsService from "../../services/UtilsService";
// import PaymentService from "../../services/PaymentService";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import "react-datepicker/dist/react-datepicker.css";
import { S3_BUCKET_URL } from "../../constants/constant.app";
import Storage from "../../services/Storage";

const controlObj = {
  value: "",
  valid: null,
  touched: false,
  nullValue: null,
};
export default function AddEvent(props) {
  const titleRef = useRef();
  // const [value, onChange] = useState(new Date());
  // const [hours, setHours] = useState(0);
  // const [minutes, setMinutes] = useState(0);
  const userDetail = Storage.getUserDetail();
  let [isFormValid, setIsFormValid] = useState(false);
  let [title, setTitle] = useState({ ...controlObj });
  let [eventId, setEventId] = useState(null);
  let [description, setDescription] = useState({ ...controlObj });
  let [startDate, setStartDate] = useState({
    ...controlObj,
    value: new Date(),
  });
  let [endDate, setEndDate] = useState({ ...controlObj, value: new Date() });
  let [type, setType] = useState({ ...controlObj });
  let [eventPlace, setEventPlace] = useState({ ...controlObj });
  let [category, setCategory] = useState({ ...controlObj, name: "category" });
  let [subCategory, setSubCategory] = useState({ ...controlObj });
  let [price, setPrice] = useState({ ...controlObj });
  let [seatCapacity, setSeatCapacity] = useState({ ...controlObj, value: 0 });
  let [trending, setTrending] = useState(false);
  let [recommended, setRecommended] = useState(false);
  let [published, setPublished] = useState(false);
  let [active, setActive] = useState(true);
  let [lat, setLat] = useState({ ...controlObj });
  let [long, setLong] = useState({ ...controlObj });
  let [categories, setCategories] = useState([]);
  let [subCategories, setSubCategories] = useState([]);
  let [avatar, setAvatar] = useState(null);
  let [posters, setPosters] = useState([]);
  let [removedImages, setremovedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const eventData = props?.event || props?.location?.state;
    let categoryList = null;
    async function getInitialData() {
      categoryList = await getCategoryList();
      if (!eventData) {
        setCategory(() =>
          setInitialInputValue(categoryList[0].categoryId, category)
        );
        const subCategoryList = await getSubCategoryList(
          categoryList[0].categoryId
        );
        setSubCategory(() =>
          setInitialInputValue(subCategoryList[0].categoryId, subCategory)
        );
      }
    }
    getInitialData();
    if (eventData) {
      const {
        eventId,
        title: eventTitle,
        description: eventDescription,
        eventStartDate,
        eventEndDate,
        eventType,
        eventLocation,
        category: eventCategory,
        subCategory: eventSubCategory,
        price: eventPrice,
        seatCapacity: eventSeatCapacity,
        isTrending,
        isRecommended,
        eventLat,
        eventLong,
        posters: eventPosters,
        isPublish,
        isActive
      } = eventData;
      // listEventMembers(eventId);
      setTitle(() => setInitialInputValue(eventTitle, title));
      setDescription(() => setInitialInputValue(eventDescription, description));
      setStartDate(() =>
        setInitialInputValue(new Date(UtilsService.UTCDateToLocalDate(eventStartDate, userDetail.zone)), startDate)
      );
      setEndDate(() => setInitialInputValue(new Date(UtilsService.UTCDateToLocalDate(eventEndDate, userDetail.zone)), endDate));
      setType(() => setInitialInputValue(eventType, type));
      setEventPlace(() => setInitialInputValue(eventLocation, eventPlace));
      setCategory(() =>
        setInitialInputValue(eventCategory.categoryId, category)
      );
      setSubCategory(() =>
        setInitialInputValue(eventSubCategory.categoryId, subCategory)
      );
      setPrice(() => setInitialInputValue(eventPrice, price));
      setSeatCapacity(() =>
        setInitialInputValue(eventSeatCapacity, seatCapacity)
      );
      setLat(() => setInitialInputValue(eventLat, lat));
      setLong(() => setInitialInputValue(eventLong, long));
      setTrending(isTrending);
      setRecommended(isRecommended);
      getSubCategoryList(eventCategory.categoryId);
      setEventId(eventId);
      setPublished(isPublish);
      setActive(isActive);
      setPosters(() => [...eventPosters]);
    } else {
      setType(() => setInitialInputValue("public", type));
    }
    if (titleRef) {
      titleRef.current.focus()
    }
  }, [props.event]);

  // useEffect(() => {
  // 	async function setSubCategoryOnCategoryChange() {
  // 		console.log("category.value", category.value);
  // 		const subCategories = await getSubCategoryList(category.value);
  // 		console.log("subCategories", subCategories);
  // 		setSubCategory(() => setInputValue(subCategories[0].subCategoryId, subCategory));
  // 	}
  // 	setSubCategoryOnCategoryChange();
  // }, [category]);

  function setInitialInputValue(value, input) {
    input.value = value;
    return { ...input };
  }
  const setInputValue = (e, input) => {
    const value = e.target.value;
    input.touched = true;
    input.value = value;
    console.log("input", input);
    return { ...input };
  };
  const setTitleInput = (e) => {
    console.log("e", e);
    setTitle(() => setInputValue(e, title));
  };
  const setDescriptionInput = (e) => {
    setDescription(() => setInputValue(e, description));
  };
  const setTypeInput = (e) => {
    setType(() => setInputValue(e, type));
  };
  const setEventPlaceInput = (e) => {
    setEventPlace(() => setInputValue(e, eventPlace));
  };
  const setCategoryInput = async (e) => {
    setCategory(() => setInputValue(e, category));
    const subCategyList = await getSubCategoryList(e.target.value);
    if (subCategyList[0]) {
      setSubCategory(() =>
        setInitialInputValue(subCategyList[0].categoryId, subCategory)
      );
    }
  };

  const setSubCategoryInput = (e) => {
    setSubCategory(() => setInputValue(e, subCategory));
  };
  const setPriceInput = (e) => {
    setPrice(() => setInputValue(e, price));
  };
  const setSeatCapacityInput = (e) => {
    setSeatCapacity(() => setInputValue(e, seatCapacity));
  };
  // const setLatInput = (e) => {
  //   setLat(() => setInputValue(e, lat));
  // };
  // const setLongInput = (e) => {
  //   setLong(() => setInputValue(e, long));
  // };
  const setStartDateInput = (date) => {
    startDate.touched = true;
    startDate.value = date;
    setStartDate({ ...startDate });
  };
  const setEndDateInput = (date) => {
    endDate.touched = true;
    endDate.value = date;
    setEndDate({ ...endDate });
  };

  const handleUploadImage = async (event) => {
    const file = event.target.files;
    // console.log("event", event.target.files[0]);
    setAvatar([...file]);
    // setAvatarUrl(URL.createObjectURL(file));
    console.log("file", file);
  };

  const removePosterImage = async (index) => {
    removedImages.push(posters[index].posterId);
    posters.splice(index, 1);
    setremovedImages([...removedImages]);
    setPosters([...posters]);
  };

  const removeUploadedPosterImage = async (index) => {
    // removedImages.push(posters[index].posterId);
    avatar.splice(index, 1);
    setAvatar([...avatar]);
  };

  const handleValidation = () => {
    // let isFormValid = false;
    title = notNullValidator(title);
    title.valid = !title.nullValue;

    description = notNullValidator(description);
    description.valid = !description.nullValue;

    startDate = notNullValidator(startDate);
    startDate.valid = !startDate.nullValue;

    category = notNullValidator(category);
    category.valid = !category.nullValue;

    endDate = notNullValidator(endDate);
    if (endDate.value < startDate.value) {
      endDate.isEndDateNotGreaterThenStartDate = true;
    } else {
      endDate.isEndDateNotGreaterThenStartDate = false;
    }
    endDate.valid = !(
      endDate.nullValue || endDate.isEndDateNotGreaterThenStartDate
    );

    eventPlace = notNullValidator(eventPlace);
    eventPlace.valid = !eventPlace.nullValue;

    price = notNullValidator(price);
    price = validatePositiveNumber(price);
    price.valid = !(price.nullValue || price.invalidPositiveNumber);

    seatCapacity = notNullValidator(seatCapacity);
    seatCapacity = validatePositiveNumber(seatCapacity);
    seatCapacity.valid = !(
      seatCapacity.nullValue || seatCapacity.invalidPositiveNumber
    );

    // lat = notNullValidator(lat);
    // lat.valid = !lat.nullValue;

    // long = notNullValidator(long);
    // long.valid = !long.nullValue;

    if (
      title.valid === true &&
      description.valid === true &&
      startDate.valid === true &&
      category.valid === true &&
      endDate.valid === true &&
      eventPlace.valid === true &&
      price.valid === true &&
      seatCapacity.valid === true
      // lat.valid === true &&
      // long.valid === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    setTitle({ ...title });
    setDescription({ ...description });
    setEventPlace({ ...eventPlace });
    setPrice({ ...price });
    setSeatCapacity({ ...seatCapacity });
    // setLat({ ...lat });
    // setLong({ ...long });
    setIsFormValid(isFormValid);
    return isFormValid;
  };

  const getCategoryList = async () => {
    const categories = await CategoryService.listCategory();
    const {
      data: { data },
    } = categories;
    setCategories([...data]);
    return data;
  };

  // const listEventMembers = async (eventId) => {
  //   const members = await PaymentService.listPayment();
  //   // const members = await EventService.listEventMembers(eventId);
  //   const {
  //     data: { data },
  //   } = members;
  //   console.log("data", data);
  //   // setCategories([...data]);
  //   return data;
  // };


  const getSubCategoryList = async (categoryId) => {
    const body = {
      parentCategoryId: categoryId,
      page: 1,
      limit: 10000
    }
    const subCategories = await CategoryService.listCategory(body);
    const {
      data: { data },
    } = subCategories;
    setSubCategories([...data]);
    return data;
  };

  const closeAddEvent = () => {
    setIsFormValid(false);
    setTitle({ ...controlObj });
    setDescription({ ...controlObj });
    setStartDate({ ...controlObj });
    setEndDate({ ...controlObj });
    setType({ ...controlObj });
    setEventPlace({ ...controlObj });
    setCategory({ ...controlObj });
    setSubCategory({ ...controlObj });
    setPrice({ ...controlObj });
    setSeatCapacity({ ...controlObj });
    setTrending(false);
    setRecommended(false);
    setPublished(false);
    setActive(true);
    setLat({ ...controlObj });
    setLong({ ...controlObj });
    setAvatar(null);
    setPosters([]);
    setremovedImages([]);
    setEventId(null);
    if (props.type === "modal") {
      closeAddEvent();
    } else {
      props.history.push({
        pathname: "/events",
      });
    }
  };

  const addEvent = async (event) => {
    try {
      event.preventDefault();
      console.log("event detail", event);
      const isEventFormValid = handleValidation();
      console.log("isEventFormValid", isEventFormValid);
      if (isEventFormValid === false) {
        return;
      }

      const obj = {
        title: title.value,
        description: description.value,
        eventType: type.value,
        eventStartDate: startDate.value,
        eventEndDate: endDate.value,
        location: eventPlace.value,
        categoryId: category.value,
        subCategoryId: subCategory.value,
        price: price.value,
        seatCapacity: seatCapacity.value,
        // lat: lat.value,
        // long: long.value,
        isRecommended: recommended,
        isTrending: trending,
        isPublish: published,
        isActive: active,
        memberData: "[]",
        inclusion: null,
        exclusion: null,
        removedImages: "[]",
      };
      console.log(avatar);
      if (avatar) {
        obj.avatar = avatar;
      }
      setIsLoading(true);
      if (eventId) {
        obj.id = eventId;
        if (removedImages && removedImages.length > 0) {
          obj.removedImages = JSON.stringify(removedImages);
        }
        await EventService.editEvent(obj);
      } else {
        await EventService.addEvent(obj);
      }
      setIsLoading(false);
      // if (props.type === "modal") {
      closeAddEvent();
      // }
      const message = eventId
        ? "Event updated successfully"
        : "Event added successfully";
      await Swal.fire({
        title: eventId ? "Edit event" : "Add Event",
        text: message,
        icon: "success",
        confirmButtonText: "Ok!",
        background: "#191c24",
      });
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: eventId ? "Edit event" : "Add Event",
        text: message,
        icon: "error",
        confirmButtonText: "Ok!",
        background: "#191c24",
      });
    }
  };

  return (
    <div>
      {/* {console.log("value", value)} */}
      <div className="position-relative">
        {props.type !== "modal" && (
          <div class="page-header">
            <h3 class="page-title">Event</h3>
          </div>
        )}
        <div className="card">
          <div className="card-body">
            {props.type === "modal" && (
              <h4 className="card-title">
                {eventId ? "Update Event" : "Add Event"}
              </h4>
            )}
            {isLoading && <CustomSpinner></CustomSpinner>}
            {/* <p className="card-description"> Basic form layout </p> */}
            <form className="forms-sample">
              {/* {avatarUrl && <div><img src={avatarUrl} alt="avatar" className="event-img"></img></div>} */}

              <div className="row">
                <Form.Group className="col-lg-6 col-md-6 col-sm-12">
                  <label>Event images</label>
                  <div class="custom-file">
                    <input
                      lang="es"
                      type="file"
                      id="customFileLang"
                      class="form-control visibility-hidden form-control-file"
                      multiple
                      onChange={handleUploadImage}
                      accept="image/*"
                    />
                    <label class="custom-file-label" for="customFileLang">
                      Upload image
                    </label>
                  </div>
                </Form.Group>
              </div>
              {(posters || avatar) && (
                <div class="form-group">
                  <div className="row">
                    <div className="col-md">
                      <div className="row no-gutters">
                        {posters &&
                          posters.map((p, index) => (
                            <div className="col-sm-4 app-img" key={index}>
                              <img
                                src={`${S3_BUCKET_URL}${p.avatarPath}`}
                                alt="event poster"
                                className="img-fluid"
                              ></img>
                              <button
                                type="button"
                                class="close-button"
                                onClick={() => removePosterImage(index)}
                              ></button>
                            </div>
                          ))}
                        {avatar &&
                          avatar.map((a, index) => (
                            <div className="col-sm-4 app-img" key={index}>
                              <img
                                src={URL.createObjectURL(a)}
                                alt="event poster"
                                className="img-fluid"
                              ></img>
                              <button
                                type="button"
                                class="close-button"
                                onClick={() => removeUploadedPosterImage(index)}
                              ></button>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <Form.Group className="col-lg-6 col-md-6 col-sm-12">
                  <label htmlFor="title">
                    Title <span className="required">*</span>
                  </label>
                  <Form.Control
                    type="text"
                    id="title"
                    value={title.value}
                    onChange={setTitleInput}
                    ref={titleRef}
                  />
                  {title.nullValue && (
                    <div className="error">Title should not empty</div>
                  )}
                </Form.Group>
              </div>
              <Form.Group>
                <label htmlFor="description">
                  Description <span className="required">*</span>
                </label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="description"
                  value={description.value}
                  onChange={setDescriptionInput}
                />
                {description.nullValue && (
                  <div className="error">Description should not empty</div>
                )}
              </Form.Group>

              <div className="row">
                <Form.Group className="col-lg-6 col-md-6 col-sm-12">
                  <label htmlFor="exampleInputPassword1">
                    Event Start <span className="required">*</span>
                  </label>
                  <DatePicker
                    selected={startDate.value}
                    onChange={(date) => setStartDateInput(date)}
                    showTimeSelect
                    // filterTime={filterPassedTime}
                    minDate={new Date()}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    isClearable
                  />
                  {startDate.nullValue && (
                    <div className="error">
                      Event start date should not empty
                    </div>
                  )}
                </Form.Group>
                <Form.Group className="col-lg-6 col-md-6 col-sm-12">
                  <label htmlFor="exampleInputPassword1">
                    Event End <span className="required">*</span>
                  </label>
                  <DatePicker
                    selected={endDate.value}
                    onChange={(date) => setEndDateInput(date)}
                    showTimeSelect
                    // filterTime={filterPassedTime}
                    minDate={startDate.value || new Date()}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    isClearable
                  />
                  {endDate.nullValue && (
                    <div className="error">Event end date should not empty</div>
                  )}
                  {endDate.isEndDateNotGreaterThenStartDate && (
                    <div className="error">
                      Event end date should be greater than start date
                    </div>
                  )}
                  {/* {endDate.value < startDate.value && (
									<div className="error">
										Event end date should be greater than start date
									</div>
								)} */}
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className="col-6">
                  <label htmlFor="type">Type</label>
                  <div>
                    <select
                      className="form-control"
                      onChange={setTypeInput}
                      value={type.value}
                    >
                      <option value="private">Private</option>
                      <option value="public">Public</option>
                    </select>
                  </div>
                </Form.Group>
              </div>
              <Form.Group>
                <label htmlFor="eventPlace">
                  Location <span className="required">*</span>
                </label>
                <Form.Control
                  type="text"
                  id="eventPlace"
                  value={eventPlace.value}
                  onChange={setEventPlaceInput}
                />
                {eventPlace.nullValue && (
                  <div className="error">Event place should not empty</div>
                )}
              </Form.Group>
              {/* <div className="row">
                <Form.Group className="col-6">
                  <label htmlFor="Latitude">
                    Latitude <span className="required">*</span>
                  </label>
                  <Form.Control
                    type="number"
                    id="Latitude"
                    value={lat.value}
                    onChange={setLatInput}
                  />
                  {lat.nullValue && (
                    <div className="error">latitude should not empty</div>
                  )}
                </Form.Group>
                <Form.Group className="col-6">
                  <label htmlFor="Longitude">
                    Longitude <span className="required">*</span>
                  </label>
                  <Form.Control
                    type="number"
                    id="Longitude"
                    value={long.value}
                    onChange={setLongInput}
                  />
                  {long.nullValue && (
                    <div className="error">longitude should not empty</div>
                  )}
                </Form.Group>
              </div> */}
              <div className="row">
                {/* <Form.Group className="col-6"> */}
                <Form.Group className="col-6">
                  <label htmlFor="category">
                    Category <span className="required">*</span>
                  </label>
                  <div>
                    <select
                      className="form-control"
                      onChange={setCategoryInput}
                      value={category.value}
                    >
                      {categories.map((c) => (
                        <option value={c.categoryId}>{c.name}</option>
                      ))}
                      {/* <option value="1">Festival</option>
													<option value="2">Sports</option> */}
                    </select>
                  </div>
                </Form.Group>
                <Form.Group className="col-6">
                  <label htmlFor="subcategory">Sub Category</label>
                  <div>
                    <select
                      className="form-control"
                      onChange={setSubCategoryInput}
                      value={subCategory.value}
                    >
                      {subCategories.map((s) => (
                        <option value={s.categoryId}>{s.name}</option>
                      ))}
                    </select>
                  </div>
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className="col-6">
                  <label htmlFor="Price">
                    Price <span className="required">*</span>
                  </label>
                  <Form.Control
                    type="number"
                    id="Price"
                    onChange={setPriceInput}
                    value={price.value}
                  />
                  {price.nullValue && (
                    <div className="error">Price not empty</div>
                  )}
                  {price.invalidPositiveNumber && (
                    <div className="error">
                      Price should be number and greater than 0
                    </div>
                  )}
                </Form.Group>
                <Form.Group className="col-6">
                  <label htmlFor="Seat Capacity">
                    Seat Capacity <span className="required">*</span>
                  </label>
                  <Form.Control
                    type="number"
                    id="Seat Capacity"
                    value={seatCapacity.value}
                    onChange={setSeatCapacityInput}
                  />
                  {seatCapacity.nullValue && (
                    <div className="error">Seat capacity should not empty</div>
                  )}
                  {seatCapacity.invalidPositiveNumber && (
                    <div className="error">
                      Seat should be number atleast 1
                    </div>
                  )}
                  {/* {seatCapacity.value >= 100000 && (
                          <div className="error">
                            Seat should be less than or equal to 100000
                          </div>
                        )} */}
                </Form.Group>
              </div>
              <Form.Group>
                <Switch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onColor="#0090e7"
                  height={20}
                  width={40}
                  onChange={() => setTrending(!trending)}
                  checked={trending}
                />
                <label
                  htmlFor="Trending"
                  style={{ paddingLeft: "10px", lineHeight: "21px" }}
                >
                  Trending
                </label>
              </Form.Group>
              <Form.Group>
                <Switch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onColor="#0090e7"
                  height={20}
                  width={40}
                  onChange={() => setRecommended(!recommended)}
                  checked={recommended}
                />
                <label
                  htmlFor="Recommended"
                  style={{ paddingLeft: "10px", lineHeight: "23px" }}
                >
                  Recommended
                </label>
              </Form.Group>
              <Form.Group>
                <Switch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onColor="#0090e7"
                  height={20}
                  width={40}
                  onChange={() => setPublished(!published)}
                  checked={published}
                />
                <label
                  htmlFor="Published"
                  style={{ paddingLeft: "10px", lineHeight: "23px" }}
                >
                  Published
                </label>
              </Form.Group>
              <Form.Group>
                <Switch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onColor="#0090e7"
                  height={20}
                  width={40}
                  onChange={() => setActive(!active)}
                  checked={active}
                />
                <label
                  htmlFor="Active"
                  style={{ paddingLeft: "10px", lineHeight: "23px" }}
                >
                  Active
                </label>
              </Form.Group>
              <button
                type="submit"
                className="btn btn-primary mr-2"
                onClick={addEvent}
              >
                Submit
              </button>
              <button className="btn btn-dark" onClick={closeAddEvent}>
                Cancel
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { Component } from 'react'
import { Provider } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Routes from './router/Routes';

import store from "./store";
import Sidebar from "./component/Sidebar/Sidebar";
import Navbar from "./component/Navbar/Navbar";
import './App.scss';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class App extends Component {
  state = {
    isFullPageLayout: false
  }
  componentDidMount() {
    this.onRouteChanged();
  }

  render() {
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar /> : '';
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar /> : '';
    // let footerComponent = !this.state.isFullPageLayout ? <Footer/> : '';
    return (
      <Provider store={store}>
        {/* <BrowserRouter> */}
          <div className="container-scroller">
            {sidebarComponent}
            <div className="container-fluid page-body-wrapper full-page-wrapper">
              {navbarComponent}
              <div className="main-panel">
                <div className="content-wrapper">
                  <Routes />
                </div>
                {/* { footerComponent } */}
              </div>
            </div>
          </div>
        {/* </BrowserRouter> */}
      </Provider>
    );
  }

  onRouteChanged() {
    // const { i18n } = this.props;
    const body = document.querySelector('body');
    if (this.props.location.pathname === '/layout/RtlLayout') {
      body.classList.add('rtl');
      // i18n.changeLanguage('ar');
    }
    else {
      body.classList.remove('rtl')
      // i18n.changeLanguage('en');
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/login','/forgot-password'];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      } else {
        this.setState({
          isFullPageLayout: false
        })
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }
}

export default withRouter(App);


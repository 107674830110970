const moment = require('moment-timezone');
export default class UtilsService {
	static formatDate(date) {
		return moment(date).format('DD-MMM-YYYY hh:mm')
	}

	static UTCDateToLocalDate(inputDate, userTimezone = 'Asia/kuwait') {
		const tzDate = moment.tz(inputDate, userTimezone).format('YYYY-MM-DDThh:mm:ss');
		const currentBrowserTimezoneDate = new Date(tzDate).toISOString();
		return currentBrowserTimezoneDate;
	}
}
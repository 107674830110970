export const routes = {
	AUTH: {
		SIGNIN: '/user/signin',
		FORGOT_PASSWORD: '/user/password/forgot',
		REFRESH_TOKEN: '/user/token/revive'
	},
	EVENT: {
		ADD_UPDATE_EVENT: '/event',
		LIST_EVENT: '/event/find/list',
		DELETE_EVENT: '/event/delete-event',
		LIST_EVENT_MEMBERS: `/event/members`,
		GET_EVENT_COUNTS: '/event/count'
	},
	POST: {
		ADD_UPDATE_POST: '/post',
		LIST_POST: '/post/find/list',
		DELETE_POST: '/post/delete-post',
		LIST_POST_MEMBERS: `/post/members`,
		GET_POST_COUNTS: '/post/count'
	},
	CATEGORY: {
		LIST_CATEGORY: '/category/category-list',
		ADD_UPDATE_CATEGORY: '/category',
	},
	SUB_CATEGORY: {
		LIST_SUB_CATEGORY: '/category/category-list'
	},
	USER: {
		GET_USER_LIST: '/user/list',
		UPDATE_USER: '/user/update',
		ADD_USER: '/user/add',
		CHANGE_PASSWORD: '/user/change-password',
		GET_ROLES: '/user/roles',
		GET_USER_COUNTS: '/user/count',
		GET_USER_LOGIN_HISTORY: '/user/login-history',
	},
	TIMEZONE: {
		GET_TIMEZONES: '/timezone'
	},
	COUNTRY: {
		GET_COUNTRIES: '/country'
	},
	PAYMENT: {
		LIST_PAYMENT: '/payment/list',
		GET_TOTAL_PAYMENT: '/payment/count'
	},
	EMAIL_TEMPLATE: {
		LIST_EMAIL_TEMPLATES: '/email-template/list',
		ADD_UPDATE_EMAIL_TEMPLATES: '/email-template',
	},
	SETTINGS: {
		LIST_SETTINGS: '/setting',
		UPDATE_SETTINGS:'/setting'
	}
}
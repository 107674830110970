import React, { useState, useEffect, useCallback } from "react";
// import Switch from "react-switch";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";
import Pagination from "../../component/Pagination/Pagination";
import PaymentDetail from "../../Modal/PaymentDetail/PaymentDetail";
import PaymentService from "../../services/PaymentService";
// import Storage from "../../services/Storage";
import CustomSpinner from "../../component/CustomSpinner/CustomSpinner";
import { RECORDS_PER_PAGE } from "../../constants/constant.app";
import { debounce } from "lodash";
import EventService from "../../services/EventService";
import UserService from "../../services/UserService";

// import ReactPaginate from 'react-paginate';

export default function Payments(props) {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [events, setEvents] = useState([]);
  const [users, setUsers] = useState([]);
  const [payments, setPayments] = useState([]);
  const [search, setSearch] = useState("");
  // const [active, setActive] = useState("");
  const [event, setEvent] = useState("");
  const [user, setUser] = useState("");
  const [isPaymentDetailModelOpen, setIsPaymentDetailModelOpen] =
    useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [paymentToView, setPaymentToView] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const pageRange = 3;
  const marginPagesDisplayed = 2;

  useEffect(() => {
    const eventId = props?.location?.state?.eventId;
    const userId = props?.location?.state?.userId;
    if (eventId) {
      setEvent(eventId);
    }
    if (userId) {
      setUser(userId);
    }
    console.log("!(userId || eventId)", !(userId || eventId));
    if (!(userId || eventId)) {
      console.log("calling list api");
      getPaymentList(1);
      setIsFirstTime(false);
    }
    getUserList();
    getEvents();
  }, []);

  useEffect(() => {
    if (isFirstTime === true) {
      getPaymentList(1);
      setIsFirstTime(false);
    }
  }, [event, user]);

  useEffect(() => {
    debouncedChangeHandler(search);
  }, [search]);

  const searchPaymentList = (search) => {
    setPage(1);
    getPaymentList(1, search);
  };

  const debouncedChangeHandler = useCallback(
    debounce(searchPaymentList, 1000),
    []
  );

  const onPageChange = (page) => {
    getPaymentList(page, search);
    setPage(page);
  };

  const getPaymentList = async (page, search) => {
    try {
      const getPaymentObj = {
        page,
        limit: RECORDS_PER_PAGE,
      };

      // if (active === "true") {
      //   getPaymentObj.isActive = true;
      // } else if (active === "false") {
      //   getPaymentObj.isActive = false;
      // }
      console.log("event", event);
      if (event) {
        getPaymentObj.eventId = event;
      }
      if (user) {
        getPaymentObj.userId = user;
      }
      // if (event) {
      //   getPaymentObj.eventId = event;
      // }

      if (search) {
        getPaymentObj.search = search;
      }
      setIsLoading(true);
      const paymentFromService = await PaymentService.listPayment(
        getPaymentObj
      );
      const {
        data: { data, totalPages },
      } = paymentFromService;
      setPageCount(totalPages);
      setPayments([...data]);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: "List payments",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    }
  };

  const getEvents = async (page, filterObj, search) => {
    const eventObj = {
      page: 1,
      pageSize: 10000000000,
    };

    setIsLoading(true);
    try {
      const events = await EventService.listEvent(eventObj);
      const { data } = events;
      setEvents(data);
      // setPageCount(totalPages);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: "List event",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    }
  };

  const getUserList = async (page, isActive = null, search) => {
    console.log("search inside user list", search, page);
    try {
      const getUserListObj = {
        page: 1,
        limit: 1000000,
      };
      setIsLoading(true);
      const usersFromService = await UserService.getUserList(getUserListObj);
      const {
        data: { data },
      } = usersFromService;
      // setTotalRecords(setTotalRecords);
      setUsers([...data]);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      const message = e?.response?.data?.message || "Something went wrong";
      await Swal.fire({
        title: "List users",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
        background: "#191c24",
      });
    }
  };

  const openPaymentModal = (payment) => {
    setPaymentToView({ ...payment });
    setIsPaymentDetailModelOpen(!isPaymentDetailModelOpen);
  };

  const closePaymentModal = () => {
    setPaymentToView(null);
    setIsPaymentDetailModelOpen(!isPaymentDetailModelOpen);
    getPaymentList(1, search);
    setPage(1);
  };

  const resetFilter = async () => {
    // setActive("");
    getPaymentList(1, search);
  };

  // const resetFilter = async () => {
  //   setActive("");
  //   getCategoryList(1, "");
  // }
  return (
    <div>
      <div class="page-header">
        <h3 class="page-title"> Payments </h3>
        {/* <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">
              <button
                class="btn btn-primary"
                onClick={() => setOpenAddCategoryPage(!openAddCategoryPage)}
              >
                Add Category
              </button>
            </li>
          </ol>
        </nav> */}
      </div>

      <div className="row">
        {isPaymentDetailModelOpen && (
          <PaymentDetail
            show={isPaymentDetailModelOpen}
            PaymentDetail={paymentToView}
            closeModal={closePaymentModal}
          ></PaymentDetail>
        )}
        <div
          className="col-lg-12 grid-margin stretch-card"
          style={{ width: "100%" }}
        >
          <div className="card">
            <div className="card-body">
              {isLoading && <CustomSpinner></CustomSpinner>}
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <Form.Group>
                    <label htmlFor="exampleInputPassword1">Search</label>
                    <Form.Control
                      type="text"
                      id="search"
                      placeholder="Search.."
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </Form.Group>
                </div>
                {/* <div className="col-3">
                  <Form.Group>
                    <label htmlFor="type">Active</label>
                    <div>
                      <select
                        className="form-control"
                        onChange={(e) => setActive(e.target.value)}
                        value={active}
                      >
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </Form.Group>
                </div> */}
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <Form.Group>
                    <label htmlFor="type">Event</label>
                    <div>
                      <select
                        className="form-control"
                        onChange={(e) => setEvent(e.target.value)}
                        value={event}
                      >
                        <option value="">All</option>
                        {events.map((e) => (
                          <option value={e.eventId}>{e.title}</option>
                        ))}
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <Form.Group>
                    <label htmlFor="type">Users</label>
                    <div>
                      <select
                        className="form-control"
                        onChange={(e) => setUser(e.target.value)}
                        value={user}
                      >
                        <option value="">All</option>
                        {users.map((u) => (
                          <option value={u.userId}>{u.name}</option>
                        ))}
                      </select>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12" style={{ margin: "auto 0px" }}>
                  <button
                    class="btn btn-primary"
                    onClick={() => getPaymentList(1, search)}
                  >
                    Apply
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-warning btn-icon-text"
                    onClick={resetFilter}
                  >
                    Reset
                  </button>
                </div>
              </div>
              {/* <p className="card-description"> Add className <code>.table</code> */}
              {/* </p> */}
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Event Ref#</th>
                      <th>Title</th>
                      <th>Name</th>
                      <th>Transaction ID</th>
                      <th>Amount</th>
                      <th>Transaction Date</th>
                      <th>Transaction Status</th>
                      {/* <th>Active</th> */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments.map((p, index) => (
                      <tr>
                        <td class="">{p.eventRefId}</td>
                        <td class="">{p.title}</td>
                        <td class="">{p.name}</td>
                        <td class="">{p.transactionId}</td>
                        <td class="">{p.amount}</td>
                        <td class="">{p.transactionDate}</td>
                        <td class="">{p.transactionStatus}</td>
                        <td>
                          <i
                            className="mdi mdi-table-edit"
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "#0090e7",
                            }}
                            title="View Details"
                            onClick={() => openPaymentModal(p)}
                          ></i>
                          &nbsp;&nbsp;
                        </td>
                      </tr>
                    ))}
                    {payments && payments.length === 0 && (
                      <tr>
                        <td colSpan="8" style={{ textAlign: "center" }}>
                          No payments available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {pageCount > 1 && (
                  <Pagination
                    totalRecords={pageCount * RECORDS_PER_PAGE}
                    itemsCountPerPage={RECORDS_PER_PAGE}
                    page={page}
                    pageCount={pageCount}
                    pageRangeDisplayed={pageRange}
                    marginPagesDisplayed={marginPagesDisplayed}
                    onPageChange={onPageChange}
                  ></Pagination>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { notNullValidator } from '../../services/Validation';
import "react-datepicker/dist/react-datepicker.css";
import UserService from '../../services/UserService';
import AddEditUser from '../../component/AddEditUser/AddEditUser';
// import DateTimePicker from 'react-datetime-picker';
const controlObj = {
	value: '',
	valid: null,
	touched: false,
	nullValue: null
}
const s3BucketUrl = `http://s3.me-south-1.amazonaws.com/stayactive-development/`

export default function AddUser(props) {
	// const [value, onChange] = useState(new Date());
	// const [hours, setHours] = useState(0);
	// const [minutes, setMinutes] = useState(0);

	const closeModal = () => {
		props.closeModal();
	}

	return <div class="modal" tabindex="-1" role="dialog" style={{ display: props.show === true ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.6)' }}>
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-body">
					<AddEditUser
						user={props.user}
						closeModal={closeModal}
						type='modal'
					></AddEditUser>
				</div>
			</div>
		</div>
	</div>
}

// add email validation logic here
export function emailValidator(control) {
    const value = control.value;
    control.nullValue = false;
    control.invalidEmail = false;
    if (value === '' || value === null) {
        control.nullValue = true;
        return control;
    }
    if (!(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(value))) {
        control.invalidEmail = true;
    } else {
        control.invalidEmail = false;
    }
    return control;
}

export function notNullValidator(control) {
    const value = control.value;
    control.nullValue = false;
    if (value === '' || value === null || value === undefined) {
        control.nullValue = true;
    } else {
        control.nullValue = false;
    }
    return control;
}

// add password validation logic here
export function passwordValidator(control) {
    const value = control.value;
    control.nullPassword = false;
    if (value === '') {
        control.nullPassword = true;
    }
    return control;
}

export function validateLength(control, length) {
    const value = control.value;
    if (value !== null && value !== '' && value !== undefined && value) {
        if (value.length === length) {
            control.invalidLength = null;
        } else {
            control.invalidLength = true;
        }
    } else if (value === null || value === '') {
        console.log('invalid length double qoutes');
        control.invalidLength = null;
    } else {

    }
    return control;
}

export function validatePhoneNumber(control) {
    const regex = /^[0-9]*$/;
    const value = control.value;
    if (value !== null && value !== undefined && value) {
        if (!value.match(regex)) {
            control.invalidPhone = true;
        } else {
            control.invalidPhone = null;
        }
    } else {
        control.invalidPhone = null;
    }
    return control;
}

export function maxLengthValidator(control, length) {
    let value = control.value ? control.value : '';
    if (value.length > length) {
        control.invalidLength = true;
    } else {
        control.invalidLength = null;
    }
    return control;
}


export function minLengthValidator(control, length) {
    let value = control.value ? control.value : '';
    if (value.length < length) {
        control.invalidMinLength = true;
    } else {
        control.invalidMinLength = null;
    }
    return control;
}

export function validatePositiveNumber(control, length) {
    let value = control.value ? control.value : '';
    if (value < 1) {
        control.invalidPositiveNumber = true;
    } else {
        control.invalidPositiveNumber = null;
    }
    return control;
}

export function validateGST(control) {
    const regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    const value = control.value;
    if (!(value === null || value === '')) {
        if (value.length !== 15) {
            control.invalidGST = true;
            return control;
        }
        const isRegexMatched = value.match(regex) ? true : false;
        if (!isRegexMatched) {
            control.invalidGST = true;
        } else {
            control.invalidGST = null;
        }
    } else {
        control.invalidGST = null;
    }
    return control;
}

export function validatePAN(control) {
    const regex = /^([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1})+$/;
    const value = control.value;
    if (!(value === null || value === '')) {
        control.value = control.value.toUpperCase();
        if (value.length !== 10) {
            control.invalidPAN = true;
            return control;
        }
        const isRegexMatched = value.match(regex) ? true : false;
        if (!isRegexMatched) {
            control.invalidPAN = true;
        } else {
            control.invalidPAN = null;
        }
    } else {
        control.invalidPAN = null;
    }
    return control;
}

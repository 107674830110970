import { useEffect, useState } from 'react';
import UserService from '../../services/UserService';
import EventService from '../../services/EventService';
import PaymentService from '../../services/PaymentService';

export default function Dashboard(props) {
	const [userCount, setUserCount] = useState({
		active: 0,
		inActive: 0,
		unVerified: 0,
		total: 0
	});
	const [eventCount, setEventCount] = useState({
		active: 0,
		total: 0,
		completed: 0
	});
	const [paymentCount, setPaymentCount] = useState({
		total: 0,
	});
	useEffect(() => {
		console.log("props", props)
		getUseCount()
		getEventCount()
		getTotalPayment()
	}, [])

	const getUseCount = async () => {
		const counts = await UserService.getUserCount();
		const { data } = counts;
		setUserCount(data);
		console.log("counts", counts)
	}

	const openPaymentPage = () => {
		props.history.push({
			pathname: '/payments',
			// state: obj
		});
	}

	const getEventCount = async () => {
		const counts = await EventService.getEventCount();
		const { data } = counts;
		setEventCount(data);
		console.log("event counts", counts)
	}

	const getTotalPayment = async () => {
		const counts = await PaymentService.getTotalPayment()
		const { data } = counts;
		setPaymentCount(data);
		console.log("payment counts", counts)
	}

	const openUsersPage = (obj) => {
		props.history.push({
			pathname: '/users',
			state: obj
		});
	}

	const openEventPage = (obj) => {
		props.history.push({
			pathname: '/events',
			state: obj
		});
	}



	return (
		<div>
			<div className="row">
				<div className="col-xl-3 col-sm-6 grid-margin stretch-card">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col-9">
									<div className="d-flex align-items-center align-self-start">
										<h3 className="mb-0">{eventCount.total}</h3>
										{/* <p className="text-success ml-2 mb-0 font-weight-medium">+3.5%</p> */}
									</div>
								</div>
								<div className="col-3">
									<div className="icon icon-box-success ">
										<span className="mdi mdi-arrow-top-right icon-item cursor-pointer" onClick={() => openEventPage(null)}></span>
									</div>
								</div>
							</div>
							<h6 className="text-muted font-weight-normal">Total Events</h6>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-sm-6 grid-margin stretch-card">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col-9">
									<div className="d-flex align-items-center align-self-start">
										<h3 className="mb-0">{eventCount.active}</h3>
										{/* <p className="text-success ml-2 mb-0 font-weight-medium">+3.5%</p> */}
									</div>
								</div>
								<div className="col-3">
									<div className="icon icon-box-success ">
										<span className="mdi mdi-arrow-top-right icon-item cursor-pointer" onClick={() => openEventPage({ isActive: true })}></span>
									</div>
								</div>
							</div>
							<h6 className="text-muted font-weight-normal">Active Events</h6>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-sm-6 grid-margin stretch-card">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col-9">
									<div className="d-flex align-items-center align-self-start">
										<h3 className="mb-0">{eventCount.completed}</h3>
										{/* <p className="text-success ml-2 mb-0 font-weight-medium">+3.5%</p> */}
									</div>
								</div>
								<div className="col-3">
									<div className="icon icon-box-success ">
										<span className="mdi mdi-arrow-top-right icon-item cursor-pointer" onClick={() => openEventPage({ status: 'completed' })}></span>
									</div>
								</div>
							</div>
							<h6 className="text-muted font-weight-normal">Completed Events</h6>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-sm-6 grid-margin stretch-card">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col-9">
									<div className="d-flex align-items-center align-self-start">
										<h3 className="mb-0">{paymentCount.total || 0}</h3>
										{/* <p className="text-success ml-2 mb-0 font-weight-medium">+3.5%</p> */}
									</div>
								</div>
								<div className="col-3">
									<div className="icon icon-box-success ">
										<span className="mdi mdi-arrow-top-right icon-item cursor-pointer" onClick={openPaymentPage}></span>
									</div>
								</div>
							</div>
							<h6 className="text-muted font-weight-normal">Total Income From Event</h6>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-sm-6 grid-margin stretch-card">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col-9">
									<div className="d-flex align-items-center align-self-start">
										<h3 className="mb-0">{userCount.total}</h3>
										{/* <p className="text-success ml-2 mb-0 font-weight-medium">+3.5%</p> */}
									</div>
								</div>
								<div className="col-3">
									<div className="icon icon-box-success ">
										<span className="mdi mdi-arrow-top-right icon-item cursor-pointer" onClick={() => openUsersPage(null)}></span>
									</div>
								</div>
							</div>
							<h6 className="text-muted font-weight-normal">Total Users</h6>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-sm-6 grid-margin stretch-card">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col-9">
									<div className="d-flex align-items-center align-self-start">
										<h3 className="mb-0">{userCount.active}</h3>
										{/* <p className="text-success ml-2 mb-0 font-weight-medium">+3.5%</p> */}
									</div>
								</div>
								<div className="col-3">
									<div className="icon icon-box-success ">
										<span className="mdi mdi-arrow-top-right icon-item cursor-pointer" onClick={() => openUsersPage({ isActive: true })}></span>
									</div>
								</div>
							</div>
							<h6 className="text-muted font-weight-normal">Active Users</h6>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-sm-6 grid-margin stretch-card">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col-9">
									<div className="d-flex align-items-center align-self-start">
										<h3 className="mb-0">{userCount.inActive}</h3>
										{/* <p className="text-success ml-2 mb-0 font-weight-medium">+3.5%</p> */}
									</div>
								</div>
								<div className="col-3">
									<div className="icon icon-box-success ">
										<span className="mdi mdi-arrow-top-right icon-item cursor-pointer" onClick={() => openUsersPage({ isActive: false })}></span>
									</div>
								</div>
							</div>
							<h6 className="text-muted font-weight-normal">Inactive Users</h6>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-sm-6 grid-margin stretch-card">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col-9">
									<div className="d-flex align-items-center align-self-start">
										<h3 className="mb-0">{userCount.unVerified}</h3>
										{/* <p className="text-success ml-2 mb-0 font-weight-medium">+3.5%</p> */}
									</div>
								</div>
								<div className="col-3">
									<div className="icon icon-box-success ">
										<span className="mdi mdi-arrow-top-right icon-item cursor-pointer" onClick={() => openUsersPage({ isUnverified: true })}></span>
									</div>
								</div>
							</div>
							<h6 className="text-muted font-weight-normal">Unverified Users</h6>
						</div>
					</div>
				</div>
				{/* <div className="col-md-4 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Users</h4>
                            <div className="aligner-wrapper">
                                <Doughnut data={this.transactionHistoryData} options={this.transactionHistoryOptions} />
                                <div className="absolute center-content">
                                    <h5 className="font-weight-normal text-whiite text-center mb-2 text-white">1200</h5>
                                    <p className="text-small text-muted text-center mb-0">Total</p>
                                </div>
                            </div>
                            <div className="bg-gray-dark d-flex d-md-block d-xl-flex flex-row py-3 px-4 px-md-3 px-xl-4 rounded mt-3">
                                <div className="text-md-center text-xl-left">
                                    <h6 className="mb-1">Transfer to Paypal</h6>
                                    <p className="text-muted mb-0">07 Jan 2019, 09:12AM</p>
                                </div>
                                <div className="align-self-center flex-grow text-right text-md-center text-xl-right py-md-2 py-xl-0">
                                    <h6 className="font-weight-bold mb-0">$236</h6>
                                </div>
                            </div>
                            <div className="bg-gray-dark d-flex d-md-block d-xl-flex flex-row py-3 px-4 px-md-3 px-xl-4 rounded mt-3">
                                <div className="text-md-center text-xl-left">
                                    <h6 className="mb-1">Tranfer to Stripe</h6>
                                    <p className="text-muted mb-0">07 Jan 2019, 09:12AM</p>
                                </div>
                                <div className="align-self-center flex-grow text-right text-md-center text-xl-right py-md-2 py-xl-0">
                                    <h6 className="font-weight-bold mb-0">$593</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
			</div>
		</div>
	)
}